<template>
  <div class='main_contanier'>
    <div class="tabbar">
      <div class="mannage_tiitle">
        格瑞丝后台管理系统
      </div>
      <el-menu v-model="elemenuIdx" mode="vertical" :default-active="activeIdx" class="el-menu-vertical"
               @select="(eve)=>{Method.handleSelect(eve)}" @open="handleOpen" @close="handleClose"
               background-color="#545c64" text-color="#fff"
               active-text-color="#ffd04b">
        <el-sub-menu :index="ele.index" v-for='ele,idx in router_list.value' :key='ele.index'>
          <template #title>
            <!-- <el-icon><location /></el-icon> -->
            <span>{{ ele.title }}</span>
          </template>
          <el-menu-item-group v-if="ele.type == 1">
            <el-menu-item :index="ele1.idx" v-for='ele1,idx1 in ele.children' :key='ele1.index'>{{ ele1.title }}
            </el-menu-item>
          </el-menu-item-group>
          <!-- <el-menu-item-group title="分组2">
            <el-menu-item index="1-3">选项3</el-menu-item>
          </el-menu-item-group> -->
          <!-- <el-menu-item v-if="ele.type == 2" :index="ele.index">
            <span>{{ele.title}}</span>
          </el-menu-item> -->
        </el-sub-menu>
        <!-- <el-menu-item index="3" disabled>
          <i class="el-icon-document"></i>
          <span slot="title">导航三</span>
        </el-menu-item>
        <el-menu-item index="4">
          <i class="el-icon-setting"></i>
          <span slot="title">导航四</span>
        </el-menu-item> -->
      </el-menu>
    </div>
    <div class="contaner_view">
      <router-view></router-view>
    </div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router';
import {getCurrentInstance, onActivated, onMounted, reactive, ref} from 'vue';

let badds = ref(0);
let Method = reactive({});
let elemenuIdx = ref("");
let activeIdx = ref("1");
let input = ref("");
let router = useRouter();
let router_list = reactive([]);
let {proxy} = getCurrentInstance();
onActivated(() => {
  console.log('activate 初始化');
  Method = reloads(0);
});
onMounted(() => {
  console.log('home初始化');
  let emoji_icon = "☺️"
  console.log("emoji_icon", emoji_icon, String(emoji_icon))
  router_list.value = [
    {
      id: 1,
      index: "1",
      type: 1,
      path: "",
      title: "TwilioSMS",
      children: [
        {
          idx: '1-1',
          type: 1,
          id: 1,
          index: "1",
          name: "home",
          path: "main_home_view",
          title: "twilio单发短信"
        },
        {
          idx: '1-2',
          type: 1,
          id: 2,
          index: "2",
          path: "sms_table_view",
          title: "twilio短信列表"
        },
        {
          idx: '1-3',
          type: 1,
          id: 3,
          index: "3",
          path: "sms_label_manage",
          title: "twilio标签管理"
        },
        // {
        // 	idx: '1-3',
        // 	type: 1,
        // 	id: 3,
        // 	index: "3",
        // 	path: "sms_sendLogs_view",
        // 	title: "twilio发送记录"
        // }
      ]
    },
    {
      idx: '2',
      id: 2,
      index: "2",
      type: 1,
      path: "",
      title: "系统功能",
      children: [
        {
          idx: '2-1',
          id: 1,
          index: "1",
          type: 2,
          path: "loginOut",
          title: "退出登录"
        }
      ]
    },
  ];
  Method = reloads(1);
});

function handleOpen(key, keyPath) {
  console.log("open", key, keyPath);
}

function handleClose(key, keyPath) {
  console.log("close", key, keyPath);
}

function reloads(idx = 0) {
  let _this = this;

  class Request {
    constructor() {

    }

    handleSelect(key, keyPath) {
      console.log("Select", key, keyPath);
      router_list.value.forEach((ele, idx) => {
        ele.children.forEach((ele1, idx1) => {
          if (ele1.idx == key) {
            if (ele1.path == "loginOut") {
              localStorage.clear()
              location.href = "/Login"
              return
            }
            // ele1.path
            router.push({name: ele1.path});
          }
        });
        // if (ele.id == key) {
        // 	router.push({ name: ele.path });
        // }
      });
      // if (condition) {
      //
      // }
    }

    initData() {
      console.log('初始化 init', elemenuIdx.value);
      // router_list.value = [
      // 	{
      // 		id: 1,
      // 		path: "/main/home",
      // 		name: "main_home_view"
      // 	}
      // ];
      // [0].children[0].idx
      console.log('router_list.value[0]', router_list.value, proxy.$router, proxy.$route);

      let routerMatched = proxy.$route.matched || "";
      // matched matched[0].path matched[1].path
      if (routerMatched.length < 2) {
        let idxs = router_list.value[0].children[0].idx;
        // console.log('idxs获取:', idxs);
        activeIdx.value = idxs;
        // console.log('选中的值:', elemenuIdx.value);
      }
      if (routerMatched.length >= 2) {
        let routerName = proxy.$route.name || '';
        // console.log('获取的内容:', routerName, router_list.value);
        router_list.value.forEach((ele, idx) => {
          ele.children.forEach((ele1, idx1) => {
            console.log('获取路径:', ele1.path);
            if (ele1.path == routerName) {
              let idxs = ele1.idx;
              // console.log('idxs获取:', idxs);
              activeIdx.value = idxs;
            }
          });
        });
      }
      router_list.value.forEach((ele, idx) => {
        ele.children.forEach((ele1, idx1) => {
          if (ele1.idx == activeIdx.value) {
            if (ele1.type == 1) {
              router.push({name: ele1.path});
            }
            if (ele1.type == 2) {
              // router.push({ name: ele.path });
              // path: "/loginOut",
              if (ele.ele1 == "/loginOut") {
                localStorage.removeItem("userInfo");
                localStorage.removeItem("assocetoken");
                location.href = "/login";
              }
            }
          }
        });
      });
    }
  }

  let method = new Request();
  // this.$set(this, 'Method', method);
  if (idx) {
    method.initData();
  }
  return method;
}

// class
</script>
<style lang='less' type='text/less' scoped>
.main_contanier {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: hidden;

  .tabbar {
    min-width: 260px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #545c64;
    padding: 15px 0;
    border-right: 0;
    overflow: hidden;

    .mannage_tiitle {
      width: 100%;
      height: 38px;
      font-size: 20px;
      font-weight: 600;
      color: #e9e9e9;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 38px;
      margin: 10px 0;
      padding-left: 30px;
    }

    .el-menu-vertical {
      width: 100%;
      flex: 1;
      border-right: 1px solid #545c64;

      .el-menu-item {
        width: 100%;
        height: 48px;
        padding-left: 30px;
        font-size: 14px;
      }
    }
  }

  .contaner_view {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: auto;
  }
}
</style>