<template>
  <div class="container_main">
    <div class="ios_emoji_icon_start" @click.stop="()=>{Method.handleShowTab()}">
      😀
    </div>
    <div :class="ios_emoji_flags ? 'show_ios_emojiLabel emoji_tabs_box':'emoji_tabs_box'">
      <el-tabs v-model="activeName" class="emoji_tabs" @tab-click="(tab,event)=>{Method.handleClick(tab,event)}"
               v-loading="tab_pane_loading">
        <el-tab-pane class="emoji_item" label="Smileys" name="Smileys">
          <!--        v-for='ele in emoji_list.value'-->
          <div class="tabs_item">
            <div class="emoji_item_list" v-for='item in emoji_icon_list.value' :key='item.id'
                 @click="()=>{Method.emojiClick(item)}">
              {{ item.emoji_text || "" }}
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane class="emoji_item" label="People & Appearance" name="People&Appearance">
          <!--        v-for='ele in emoji_list.value'-->
          <div class="tabs_item">
            <div class="emoji_item_list" v-for='item in emoji_icon_list.value' :key='item.id'
                 @click="()=>{Method.emojiClick(item)}">
              {{ item.emoji_text || "" }}
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane class="emoji_item" label="Objects" name="Objects">
          <!--        v-for='ele in emoji_list.value'-->
          <div class="tabs_item">
            <div class="emoji_item_list" v-for='item in emoji_icon_list.value' :key='item.id'
                 @click="()=>{Method.emojiClick(item)}">
              {{ item.emoji_text || "" }}
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import {defineProps, getCurrentInstance, onActivated, onMounted, reactive, ref} from 'vue'

let emoji_list = reactive([])
let emoji_icon_list = reactive([])
// /emoji/get_emoji_list
let Method = reactive({});
let a1 = ref("")
let _this = getCurrentInstance()["proxy"]
let activeName = ref("Smileys")
let tab_pane_loading = ref(false)
let ios_emoji_flags = ref(false)
const props = defineProps({
  "emoji_change": {
    type: Function
  },
})
// const emits = defineEmits(['emoji_change'])
onActivated(() => {
  console.log('初始化');
  Method = reloads();
});
onMounted(() => {
  console.log('home初始化', props.emoji_change);
  emoji_list.value = [
    {
      "id": '1',
      "emoji_name": "Smileys",
      "emoji_type": 1
    },
    {
      "id": '2',
      "emoji_name": "People&Appearance",
      "emoji_type": 2
    },
    {
      "id": '3',
      "emoji_name": "Objects",
      "emoji_type": 3
    }
  ]
  Method = reloads(1);
});

function reloads(idx = 0) {
  let _that = this

  class Request {
    constructor() {

    }

    emojiClick(item) {
      console.log("eve", item)
      props.emoji_change(item)
      // emoji_text
    }

    checkedEmojiInit(checked_name) {
      console.log("item", checked_name)
      let emojiType = ""
      for (let i = 0; i < emoji_list.value.length; i++) {
        let emoji_ele = emoji_list.value[i]
        if (emoji_ele.emoji_name == checked_name) {
          emojiType = emoji_ele.emoji_type
          break
        }
      }
      _this.$axhttp({
        method: "get",
        url: "/api/emoji/get_emoji_list",
        params: {
          "emojiType": emojiType
        }
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          emoji_icon_list.value = [...res.data]
          tab_pane_loading.value = false
        }
        // tab_pane_loading.value = false
      }).catch((err) => {
        console.log('错误信息?', err);
        tab_pane_loading.value = false
      })
    }

    initData() {
      // activeName = "Smileys"
      tab_pane_loading.value = true
      this.checkedEmojiInit("Smileys")
    }

    handleClick(tab, event) {
      console.log("切换 tab", tab, event, tab.paneName)
      let select_value = tab.paneName || ""
      tab_pane_loading.value = true
      this.checkedEmojiInit(select_value)
      // activeName.value
    }

    handleShowTab() {
      ios_emoji_flags.value = !ios_emoji_flags.value
    }
  }

  let method = new Request();
  if (idx) {
    method.initData();
  }
  return method
}

</script>

<style scoped lang='less' type='text/less'>
.container_main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 135px;
  flex-direction: column;

  .ios_emoji_icon_start {
    font-size: 22px;
    cursor: pointer;
  }

  .emoji_tabs_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .emoji_tabs {

      transition: all 0.5s ease-in-out;
      //overflow: hidden;
      opacity: 0;
      position: absolute;
      z-index: -1;
      transform: translateY(-100%);
      min-height: 120px;
      height: auto;
    }

    &.show_ios_emojiLabel {
      //height: 120px;
      //transform: translateY(0%);
      //opacity: 1;

      .emoji_tabs {
        position: relative;
        transform: translateY(0%);
        opacity: 1;
        z-index: 1;
      }
    }
  }

  .emoji_tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .emoji_item {
      width: 100%;
      height: 100%;
      background: #e2e2e2;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 10px;

      .tabs_item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .emoji_item_list {
          font-size: 24px;
          cursor: pointer;
          margin-right: 4px;
        }
      }
    }

    /deep/ .el-tabs__header {
      width: 100%;
      margin-bottom: 0;
    }

    /deep/ .el-tabs__content {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: initial;
    }
  }
}
</style>