<template>
  <div class="quick_template_contanier">
    <el-button class="quick_template" size="large" plain round @click.stop="()=>{Methods.quick_template_btn()}">
      快捷模板
    </el-button>
    <div :class="qucik_box_flags ? 'show_quick_template qucik_item_list':'qucik_item_list'">
      <div class="qucik_item"
           v-for='ele in qucik_item_list.value' :key='ele.id'
           :data-text="ele.quick_template_text"
           @click.stop="(event)=>{Methods.handelQucik(ele)}">
        <el-tooltip
            class="box-item"
            effect="dark"
            :content="ele.quick_template_text"
            placement="top"
        >
        <el-button class="quick_item_box" size="large" plain round>
          {{ ele.quick_template_name }}
        </el-button>
        </el-tooltip>

      </div>
    </div>
  </div>
</template>
<script setup>
import {defineProps, getCurrentInstance, onActivated, onMounted, reactive, ref} from 'vue';

let _this = getCurrentInstance()["proxy"]
let qucik_item_list = reactive([])
let qucik_box_flags = ref(false)
let Methods = reactive({})

const props = defineProps({
  "quickTemplate_check": {
    type: Function
  },
})
onActivated(() => {
  console.log('初始化');
  Methods = reloads();
});
onMounted(() => {
  console.log('home初始化',);
  Methods = reloads(1);
});

function reloads(idx = 0) {
  let _that = this

  class Request {
    constructor() {

    }

    handelQucik(item) {
      console.log("item", item)
      props.quickTemplate_check(item)
    }

    initData() {
      qucik_item_list.value = [
        {
          "id": "1",
          "quick_template_text": "hi greats1",
          "quick_template_name": "model1"
        },
        {
          "id": "2",
          "quick_template_text": "hi greats2",
          "quick_template_name": "model1"
        },
        {
          "id": "3",
          "quick_template_text": "hi greats3",
          "quick_template_name": "model1"
        }
      ]
      return
      // _this.$axhttp({
      //   method: "get",
      //   url: "/api/quickTemplate/getQuickList",
      //   params: {}
      // }).then((res) => {
      //   console.log('输出res:', res);
      //   if (res.code == 200) {
      //     console.log("res.data")
      //     if (res.data.length > 0) {
      //       qucik_item_list = [...res.data]
      //     }
      //   }
      // }).catch((err) => {
      //   console.log('错误信息?', err);
      // })

    }

    quick_template_btn() {
      console.log("点击")
      qucik_box_flags.value = !qucik_box_flags.value
    }
  }

  let method = new Request();
  if (idx) {
    method.initData();
  }
  return method
}
</script>
<style lang='less' scoped>
.quick_template_contanier {
  min-height: 32px;
  left: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  margin-left: 15px;

  .quick_template {
    width: 100%;
    height: 32px;
    font-size: 14px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
  }

  .qucik_item_list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 15px;

    .qucik_item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      width: 100%;
      height: 32px;
      transition: 0.5s all ease-in-out;
      transform: scale(0) translateY(-100%);

      .quick_item_box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.show_quick_template {
      .qucik_item {
        width: 100%;
        transform: scale(1) translateY(0);
      }

    }
  }
}
</style>