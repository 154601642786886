<template>
  <!-- <router-link to="/">Home</router-link>  -->
  <!-- <router-link to="/main">About</router-link> -->
  <router-view></router-view>
</template>
<script setup>
import {onMounted} from 'vue';

onMounted(() => {
  // console.log("appmounted", process.env)
  // process.env["VUE_APP_BASE_URL"] = 'https://m.rashineehair.com'
})
// export default {
// 	name: "app",
//   mounted() {
//     // process.env.VUE_APP_BASE_URL = 'https://m.rashineehair.com'
//     console.log("appmounted",process)
//   },
// }
</script>
<style lang='less' type='text/less'>
@import url("./assets/styles/main.less");

#app {
  width: 100%;
  height: 100%;
}
</style>
