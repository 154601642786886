<template>
  <div class="component_c">
    <div class="lens">
      {{props.min_lens}}
    </div>
    <div class="signs">/</div>
    <div class="all_lens">{{props.max_lens}}</div>
  </div>
</template>


<script setup>
import {defineProps, getCurrentInstance, onBeforeMount, onMounted, reactive} from 'vue'

let _this = getCurrentInstance()["proxy"]
const props = defineProps({
  min_lens: {
    type: Number,
    default: 0
  },
  max_lens: {
    type: Number,
    default: 150
  },
})
let Method = reactive({});

function reloads(Method, idx = 0) {
  let _this = this;

  class Request {
    constructor() {

    }

    initData() {

    }
  }

  let method = new Request();
  if (idx) {
    method.initData();
  }
  return method
}

onBeforeMount(() => {
  Method = reloads(0)
  console.log("beforeMount", props)
})
onMounted(() => {
  Method = reloads(1)
  console.log("mounted", props)
})
</script>


<style scoped lang="less">
.component_c {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 32px;
  position: absolute;
  color: #333;
  user-select: none;
  .lens {
    font-size: 12px;
    font-weight: 500;
  }

  .signs {
    font-size: 14px;
  }

  .all_lens {
    font-size: 12px;
    font-weight: 500;
  }
}
</style>