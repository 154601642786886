<template>
  <div class="mainContainer" v-loading.fullscreen.lock="pageLoading">
    <div class="sms_label_title">
      <h1>Message User Label Manage</h1>
    </div>
    <div class="search_tabs">
      <div class="search_contanier">
        <div class="select_item_box">
          <div class="select_label">标签名称</div>
          <div class="select_box">
            <el-input v-model="search_data.searchLabel" placeholder="搜索标签名称"></el-input>
          </div>
        </div>
        <div class="select_item_box">
          <div class="select_label">标签状态</div>
          <div class="select_box">
            <el-select v-model="search_data.labelStatus" filterable placeholder="请选择标签状态">
              <el-option v-for="item in labelStatusArr.value" :key="item.value" :label="item.label"
                         :value="item.value"/>
              <!-- 默认的 发送失败的  过滤的  空号（录入的号码 看看之后能不能识别出来） -->
            </el-select>
          </div>
        </div>
        <div class="search_btn">
          <el-button class="search_sid" type="primary" @click="(eve)=>{Method.filterSearch(eve)}" plain>筛选</el-button>
          <el-button class="search_sid" type="primary" @click="(eve)=>{
            Method.searchRefresh(eve)
          }" plain>重置
          </el-button>
        </div>
      </div>
    </div>
    <div class="configuration_box">
      <el-button class="configuration_btn" type="primary" @click="(eve)=>{Method.createLabel(eve)}" plain round>
        新建标签
      </el-button>
      <!--      <el-button class="configuration_btn" type="primary" @click="(eve)=>{Method.deleteMoreLabels(eve)}" plain round>-->
      <!--        删除标签-->
      <!--      </el-button>-->
      <!--      <el-button class="configuration_btn" type="primary" @click="(eve)=>{Method.clearMoreLabels(eve)}" plain round>-->
      <!--        清理标签-->
      <!--      </el-button>-->
    </div>
    <div class="table_boxs">
      <el-table class="table_main" ref="multipleTable" :data="tableData.value" tooltip-effect="dark" :max-height="530"
                @selection-change="(eve)=>{Method.handleSelectionChange(eve)}" :highlight-current-row="true"
                :border="true">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="标签名称" prop="sms_label" width="140">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="标签描述" width="200" prop="sms_label_describe">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="false" label="用户绑定数量" width="120" prop="sms_label_user_number">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="false" label="标签状态" width="120">
          <template #default="scope">
            <!--            prop="Status"-->
            <div class="table_column_body">
              {{ scope.row.sms_label_status == 1 ? "启用" : scope.row.sms_label_status == 2 ? "禁用" : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="创建时间" prop="createTimer">
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <!-- <el-button @click="Method.handleConfigs(scope.$index, scope.row,'edit')" plain round>编辑</el-button> -->
            <el-button class="configuration_btn" @click="(eve)=>{Method.handleConfigs(scope.$index, scope.row,'info')}"
                       plain round>
              查看
            </el-button>
            <el-button class="configuration_btn" type="primary"
                       @click="(eve)=>{Method.handleConfigs(scope.$index, scope.row,'Edit')}" plain round>
              编辑
            </el-button>
            <el-button class="configuration_btn" type="danger"
                       @click="()=>{Method.handleConfigs(scope.$index, scope.row,'Delete')}" plain round>
              删除
            </el-button>
            <el-button class="configuration_btn"
                       :type="scope.row.sms_label_status == 1 ? 'warning' : scope.row.sms_label_status == 2 ? 'primary' : ''"
                       @click="()=>{Method.handleConfigs(scope.$index, scope.row,'ChangeStatus')}" plain round>
              {{ scope.row.sms_label_status == 1 ? "禁用" : scope.row.sms_label_status == 2 ? '启用' : "" }}
            </el-button>
            <el-button class="configuration_btn" type="warning"
                       @click="(eve)=>{Method.handleConfigs(scope.$index, scope.row,'clear')}" plain round>
              清理
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination_box">
        <el-pagination class='pagination_main' background layout="prev, pager, next" :pager-count="7"
                       :total="paginatotal" :page-sizes="[10, 20, 30, 40, 50, 100]" :default-page-size="50"
                       :current-page="page_current"
                       @current-change="(num)=>{Method.pageChange(num)}">
        </el-pagination>
        <div class="total_num">
          当前总数{{ paginatotal }}条
        </div>
        <!-- paginatotal.value page-sizes="[10, 20, 30, 40, 50, 100]" -->
      </div>
    </div>
    <el-dialog class="dialog_mainaer" v-model="dialogFormVisible1"
               :title="dialogConfigura1.dialogType == 4 ? '删除标签' : dialogConfigura1.dialogType == 5 ? '禁用标签' : dialogConfigura1.dialogType == 6 ? '启用标签' :  dialogConfigura1.dialogType == 7 ? '清理标签' : dialogConfigura1.dialogType == 8 ? '批量删除标签' : ''"
               @close="Method.dialogCancle1" width="30%">
      <div class="main_container">
        <div class="toast_box">
          {{
            dialogConfigura1.dialogType == 4 ? `删除标签 ${dialogConfig2.label_title} 会清除已分组的用户数据，请谨慎删除！` : dialogConfigura1.dialogType == 5 ? `禁用标签 ${dialogConfig2.label_title}后，当前标签会失效，请谨慎！` : dialogConfigura1.dialogType == 6 ? `启用标签 ${dialogConfig2.label_title}中...！` : dialogConfigura1.dialogType == 7 ? `清理标签${dialogConfig2.label_title}会使标签绑定的 ${dialogConfig2.label_title}分组用户自动解绑，请慎重选择！` : ""
          }}
        </div>
        <!--        `编辑标签${dialogConfigura1.label}`-->
      </div>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="Method.dialogCancle1">{{ dialogConfigura1.cancleTitle || "取消" }}</el-button>
					<el-button type="primary"
                     @click="(eve)=>{Method.dialogcEntry1(eve)}">{{ dialogConfigura1.entryTitle || "确定" }}</el-button>
				</span>
      </template>
    </el-dialog>
    <el-dialog class="dialog_label_win" v-model="dialogFormVisible2" :title="dialogConfigura2.title || '删除标签'"
               @close="Method.dialogCancle2" width="50%">
      <div class="main_container">
        <el-form :model="dialogConfig1">
          <el-form-item label="标签名称" :label-width="120" class="form_item_box">
            <div class="send_sms_item_box">
              <el-input class="ipt_box" v-model="dialogConfig1.sms_label_title" type="text" autosize
                        placeholder="请输入标签名称" :disabled="dialogConfigura2.dialogType == 3 ? true:false">
                <!--                :disabled=" ? true : false "-->
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="标签描述" :label-width="120" class="form_item_box">
            <div class="send_sms_item_box send_sms_describe">
              <el-input class="ipt_box ipt_describe" v-model="dialogConfig1.sms_label_describe" type="textarea" autosize
                        placeholder="请输入标签描述" :maxlength="250"
                        :disabled="dialogConfigura2.dialogType == 3 ? true:false">
              </el-input>
              <TextareaLens class="text_describe_lens" :max_lens="250"
                            :min_lens="!!dialogConfig1.sms_label_describe ? dialogConfig1.sms_label_describe.length : 0"></TextareaLens>
            </div>
          </el-form-item>
          <el-form-item label="标签状态" :label-width="120" class="form_item_box">
            <div class="send_sms_item_box">
              <el-input class="ipt_box" :disabled="true"
                        :value="dialogConfig1.sms_label_status == 1 ? '启用': dialogConfig1.sms_label_status == 2 ? '禁用':'其他'"
                        type="text"
                        autosize>
              </el-input>
              <!--              v-model="dialogConfig1.sms_label_use_status"-->
            </div>
          </el-form-item>
          <el-form-item label="标签状态" :label-width="120" class="form_item_box">
            <div class="send_sms_item_box">
              <el-switch
                  v-model="dialogConfig1.sms_label_status"
                  size="large"
                  active-text="启用"
                  :active-value="1"
                  inactive-text="禁用"
                  :inactive-value="2"
                  :disabled="dialogConfigura2.dialogType == '1' || dialogConfigura2.dialogType == '2' ? false:true"
              />
            </div>
          </el-form-item>
          <el-form-item label="标签人数" :label-width="120" class="form_item_box"
                        v-if="dialogConfigura2.dialogType == 3 ">
            <!--            dialogConfig1.sms_label_amount_used != 0 ? dialogConfig1.sms_label_amount_used : '0' && dialogConfig1.sms_label_amount_used != ''-->
            <el-input class="ipt_box" :disabled="true"
                      v-model="dialogConfig1.sms_label_amount_used"
                      type="text"
                      autosize>
            </el-input>
          </el-form-item>
        </el-form>
        <!--        `编辑标签${dialogConfigura1.label}`-->
      </div>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="Method.dialogCancle2">{{ dialogConfigura2.cancleTitle || "取消" }}</el-button>
					<el-button v-if="dialogConfigura2.dialogType == '1' || dialogConfigura2.dialogType == '2'" type="primary"
                     @click="(eve)=>{Method.dialogcEntry2(eve)}">{{ dialogConfigura2.entryTitle || "确定" }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import {getCurrentInstance, onBeforeMount, onMounted, reactive, ref} from 'vue';
import TextareaLens from '../../component/TextareaLens.vue'

let pageLoading = ref(false)
let datas = reactive({})
let _this = getCurrentInstance()["proxy"]
let Method = reactive({});
let searchType = ref(1)
let search_data = reactive({});
let tableData = reactive([])
let labelStatusArr = reactive([])
let paginatotal = ref(0);
let page_lens = ref(0)
let page_current = ref(1);
let page_size = ref(10)
let dialogFormVisible = ref(false);
let dialogFormVisible1 = ref(false);
let dialogFormVisible2 = ref(false);
let dialogConfigura1 = reactive({})
let dialogConfigura2 = reactive({})
let dialogConfig1 = reactive({})
let dialogConfig2 = reactive({})

function reloads(idx = 0) {
  let _that = this;

  class Request {
    constructor() {
    }

    reloadSearchs() {
      labelStatusArr.value = [
        {
          laebl: 1,
          value: "启用"
        },
        {
          laebl: 2,
          value: "禁用"
        }
      ]
    }

    httpRequests(type = 1) {
      let limit = page_size.value;
      let param_data = {
        "limit": limit,
        "offset": (page_current.value - 1) * limit,
      };
      // searchLabel
      // labelStatus
      if (!pageLoading.value) {
        pageLoading.value = true
      }
      if (type == 1) {
        console.log("获取 list")
      }
      if (type == 2) {
        console.log("筛选")
        param_data["searchType"] = "1"
        if (search_data.searchLabel && search_data.searchLabel != "") {
          param_data["searchLabel"] = search_data.searchLabel || ""
        }
        console.log("获取 ", search_data.labelStatus)
        if (search_data.labelStatus && search_data.labelStatus != "") {
          param_data["labelStatus"] = search_data.labelStatus == "启用" ? 1 : search_data.labelStatus == "禁用" ? 2 : 1
        }
        // search_data.searchLabel
        // search_data.labelStatus
      }
      _this.$axhttp({
        method: "get",
        url: "/api/label/list",
        params: {
          ...param_data
        }
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          console.log("res:0")
          tableData.value = [...res.data.allCont];
          paginatotal.value = res.data.total;
          // data.total
          page_lens.value = Math.ceil(res.data.total / limit);
          pageLoading.value = false
        } else {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请求失败，参数错误！',
            duration: 2000
          })
          pageLoading.value = false
        }
      }).catch((err) => {
        console.log('错误信息?', err);
        pageLoading.value = false
      })
    }

    initData() {
      console.log("initData")
      this.httpRequests(1);
      this.reloadSearchs();
    }

    filterSearch() {
      console.log("筛选")
      //   筛选
      searchType.value = "2";
      this.httpRequests(2);
    }

    searchRefresh() {
      console.log("重置")
      searchType.value = "1";
      for (const key in search_data) {
        if (Object.hasOwnProperty.call(search_data, key)) {
          search_data[key] = "";
        }
      }
      this.httpRequests(1);
    }

    createLabel(eve) {
      console.log("创建标签", eve)
      dialogConfigura2.title = "新增标签"
      dialogConfigura2.dialogType = 1 // 1 新增 2 编辑 3 删除 4
      dialogConfigura2.entryTitle = "保存"
      dialogConfig1.sms_label_status = 1
      dialogFormVisible2.value = true
    }


    handleConfigs(index, rowObj, type) {
      console.log("删除标签", index, rowObj, type)
      if (type == "Edit") {
        console.log("编辑")
        dialogConfigura2.title = "编辑标签"
        dialogConfigura2.dialogType = 2 // 1 新增 2 编辑 3 查看  4 删除
        dialogConfigura2.entryTitle = "保存"
        dialogFormVisible2.value = true
        dialogConfig1.sms_label_status = rowObj.sms_label_status
        dialogConfig1.sms_label_describe = rowObj.sms_label_describe
        dialogConfig1.sms_label_title = rowObj.sms_label
        dialogConfig1.label_id = rowObj.id
        dialogConfig1.sms_label_amount_used = rowObj.sms_label_user_number || 0
        //   id: 5
        //   sms_label: "VIP组"
        //   sms_label_describe: "vip客户，要经常维护，并且每周都需要发送维护短信"
        //   sms_label_status: 1
        //   sms_label_type: 1
        //   sms_label_user_id: null
        //   sms_label_user_number: null
      }
      if (type == "Delete") {
        console.log("删除")
        // return
        dialogFormVisible1.title = "删除标签"
        dialogConfigura1.dialogType = 4 // 4 删除  5 禁用 6 启用  7 清理
        dialogConfig2.label_id = rowObj.id
        dialogConfig2.label_title = rowObj.sms_label
        dialogFormVisible1.value = true
        // dialogConfigura1

      }
      if (type == "ChangeStatus") {
        console.log("changeStatus 启动 禁用", rowObj, rowObj.labelStatus)
        // scope.row.sms_label_status == 1 ? "禁用" : '启用'
        if (rowObj.sms_label_status == 1) {
          console.log("禁用")
          dialogFormVisible1.title = "禁用标签"
          dialogConfigura1.dialogType = 5 // 4 删除  5 禁用 6 启用  7 清理
          dialogConfig2.label_id = rowObj.id
          dialogConfig2.label_title = rowObj.sms_label
          dialogFormVisible1.value = true
        }
        if (rowObj.sms_label_status == 2) {
          console.log("启用")
          dialogFormVisible1.title = "启用标签"
          dialogConfigura1.dialogType = 6 // 4 删除  5 禁用 6 启用  7 清理
          dialogConfig2.label_id = rowObj.id
          dialogConfig2.label_title = rowObj.sms_label
          dialogFormVisible1.value = true
        }
        //   启用 禁用
      }
      if (type == "clear") {
        console.log("clear")
        dialogFormVisible1.title = "清理标签"
        dialogConfigura1.dialogType = 7 // 4 删除  5 禁用 6 启用  7 清理
        dialogConfig2.label_id = rowObj.id
        dialogConfig2.label_title = rowObj.sms_label
        dialogConfig2.label_status = rowObj.sms_label_status
        dialogFormVisible1.value = true
      }
      if (type == 'info') {
        // dialogConfigura2.dialogType = '3'
        dialogConfigura2.title = "标签信息"
        dialogConfigura2.dialogType = 3 // 1 新增 2 编辑 3 查看  4 删除
        dialogConfigura2.cancleTitle = "关闭"
        dialogFormVisible2.value = true
        dialogConfig1.sms_label_status = rowObj.sms_label_status
        dialogConfig1.sms_label_describe = rowObj.sms_label_describe
        dialogConfig1.sms_label_title = rowObj.sms_label
        dialogConfig1.label_id = rowObj.id
        dialogConfig1.sms_label_amount_used = rowObj.sms_label_user_number || 0
      }
    }

    // deleteMoreLabels
    //  clearMoreLabels
    deleteMoreLabels() {
      console.log("删除标签")
      // if () {
      //   正常情况下的逻辑
      // }
      dialogFormVisible1.value = true
      // dialogConfigura1.constructor
      dialogConfigura1.dialogType = 8 // 4 删除 5 禁用  6 清理
      //   /label/delete/list
    }

    clearMoreLabels() {
      console.log("批量清理标签")
      dialogFormVisible1.value = true
      // dialogConfigura1.constructor
      dialogConfigura1.dialogType = 9
    }

    dialogcEntry1() {
      console.log("弹窗确定1")
      // 4 删除  5 禁用   6 启用  7 清理
      if (dialogConfigura1.dialogType == 4) {
        // dialogConfig2.label_id = rowObj.id
        // dialogConfig2.label_title = rowObj.sms_label
        // dialogFormVisible1.value = true
        pageLoading.value = true
        this.deleteLabel()
      }
      if (dialogConfigura1.dialogType == 5) {
        // 禁用
        pageLoading.value = true
        this.changeLabelStatus(2)
      }
      if (dialogConfigura1.dialogType == 6) {
        // 启用
        pageLoading.value = true
        this.changeLabelStatus(1)
      }
      if (dialogConfigura1.dialogType == 7) {
        // 清理
        pageLoading.value = true
        this.clearLabelUseCustom()
      }
      // dialogConfigura2
    }

    clearLabelUseCustom() {
      console.log("clearn")
      // dialogConfig2.label_id = rowObj.id
      // dialogConfig2.label_title = rowObj.sms_label
      // dialogFormVisible1.value = true
      // dialogConfig2.label_status
      _this.$axhttp({
        method: "post",
        url: "/api/label/clearLabel/once",
        data: {
          "id": dialogConfig2.label_id || "",
          "label_title": dialogConfig2.label_title || "",
          "status": dialogConfig2.label_status
        }
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          _this.$message({
            showClose: true,
            type: 'success',
            center: true,
            message: res.msg || '清理成功！',
            duration: 1500
          })
          let timer = setTimeout(() => {
            this.dialogCancle1()
            this.httpRequests()
          }, 1000)
        } else {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: res.msg || "",
            duration: 1500
          })
          pageLoading.value = false
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      })
    }

    changeLabelStatus(type) {
      // dialogConfig2.label_id
      // dialogConfig2.label_title
      console.log("status", type)
      _this.$axhttp({
        method: "post",
        url: "/api/label/statusChange",
        data: {
          id: dialogConfig2.label_id,
          label_title: dialogConfig2.label_title,
          status: type
        }
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          _this.$message({
            showClose: true,
            type: 'success',
            center: true,
            message: '编辑成功！',
            duration: 1500
          })
          let timer = setTimeout(() => {
            this.dialogCancle1()
            this.httpRequests()
          }, 1000)
        } else {
          _this.$message({
            showClose: true,
            type: 'error',
            center: true,
            message: res.msg || "参数错误，编辑失败！",
            duration: 1500
          })
          pageLoading.value = false
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      })
    }

    dialogcEntry2() {
      console.log("弹窗确定2", this.pushLabel)
      if (dialogConfigura2.dialogType == '1') {
        pageLoading.value = true
        this.pushLabel()
      }
      if (dialogConfigura2.dialogType == '2') {
        pageLoading.value = true
        this.editLabel()
      }
    }

    deleteLabel() {
      console.log("删除")
      //   dialogConfig2.label_id  = rowObj.id
      // dialogConfig2.label_title = rowObj.sms_label
      // dialogFormVisible1.value = true
      let label_id = dialogConfig2.label_id
      let label_title = dialogConfig2.label_title
      _this.$axhttp({
        method: "post",
        url: "/api/label/delete",
        data: {
          id: label_id,
          label_title: label_title
        }
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          console.log("res")
          _this.$message({
            showClose: true,
            type: 'success',
            center: true,
            message: '删除成功！',
            duration: 1500
          })
          let timer = setTimeout(() => {
            this.dialogCancle1()
            this.httpRequests()
          }, 1000)
        } else {
          _this.$message({
            showClose: true,
            type: 'error',
            center: true,
            message: res.msg || "参数错误，删除失败！",
            duration: 1500
          })
          pageLoading.value = false
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      })
    }

    editLabel() {
      console.log("编辑")
      // dialogConfig1.label_id
      let sms_label_describe = dialogConfig1.sms_label_describe
      let sms_label_title = dialogConfig1.sms_label_title
      let sms_label_status = dialogConfig1.sms_label_status
      _this.$axhttp({
        method: "post",
        url: "/api/label/EditLabel",
        data: {
          id: dialogConfig1.label_id,
          sms_label_describe: sms_label_describe,
          sms_label: sms_label_title,
          sms_label_status: sms_label_status,
        }
      }).then((res) => {
        if (res.code == 200) {
          console.log("输出res", res)
          // pageLoading.value = false
          _this.$message({
            showClose: true,
            type: 'success',
            center: true,
            message: '编辑成功！',
            duration: 1500
          })
          let timer = setTimeout(() => {
            this.dialogCancle2()
            this.httpRequests()
          }, 1000)
        } else {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请求失败，参数错误！',
            duration: 1500
          })
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      })
    }

    pushLabel() {
      let sms_label_describe = dialogConfig1.sms_label_describe
      let sms_label_title = dialogConfig1.sms_label_title
      let sms_label_status = dialogConfig1.sms_label_status
      _this.$axhttp({
        method: "post",
        url: "/api/label/createALabel",
        data: {
          sms_label: sms_label_title,
          sms_label_describe: sms_label_describe,
          sms_label_status: sms_label_status,
        }
      }).then((res) => {
        console.log('输出res:', res);
        _this.$message({
          showClose: true,
          type: 'success',
          center: true,
          message: '创建成功！',
          duration: 1500
        })
        let timer = setTimeout(() => {
          this.dialogCancle2()
          this.httpRequests()
        }, 1500)
      }).catch((err) => {
        console.log('错误信息?', err);
        this.dialogCancle2()
        pageLoading.value = false
      })
    }

    dialogCancle1() {
      console.log("关闭")
      dialogConfigura1.title = ""
      dialogConfigura1.dialogType = 4 //  4 删除  5 禁用 6 清理
      dialogFormVisible1.value = false
    }

    dialogCancle2() {
      console.log("关闭 弹窗2")
      dialogConfig1.title = ""
      dialogConfig1.dialogType = 1 // 1 新增 2 编辑 3 查看
      dialogFormVisible2.value = false
      for (const key in dialogConfig1) {
        if (Object.hasOwnProperty.call(dialogConfig1, key)) {
          if (key == "dialogType") {
            dialogConfig1[key] = "1";
            continue;
          }
          dialogConfig1[key] = "";
        }
      }
    }

    handleEditLabel() {

    }

    handleSelectionChange(res) {
      // console.log('获取选中的值:', res);
      // select_checkbox = [...res];
    }

  }

  let method = new Request();
  // Method.value = method
  if (idx) {
    method.initData();
  }
  return method;
}

onBeforeMount(() => {
  console.log("onBeforeMount")
  Method = reloads(0)
})
onMounted(() => {
  console.log("onMounted", Method)
  Method = reloads(1)
})

// watch(()=>{
//   console.log("")
// })
</script>
<style lang='less' type='text/less' scoped>
.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;

  .sms_label_title {
    width: 100%;
    height: 44px;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0;
    padding-left: 30px;

    h1 {
      font-size: 16px;
      color: rgb(18, 28, 45);
      font-weight: 700;
      display: block;
      line-height: 44px;
    }
  }

  .search_tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 10px;

    .search_contanier {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .select_item_box {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .select_label {
          min-width: 120px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 32px;
          font-size: 14px;
          color: #333;
        }

        .select_box {
          height: 32px;
          margin-left: 15px;
          // height: 220px;
          min-width: 220px;
        }
      }

      .search_btn {
        margin-left: 30px;
      }
    }
  }

  .configuration_box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 20px;

    .configuration_btn {
      margin-right: 10px;
    }
  }

  .table_boxs {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .pagination_box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin: 15px 0;

      .pagination_main {
      }

      .total_num {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        padding-left: 30px;
      }
    }

    .table_main {
      width: 100%;

      .media {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .img_box {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .el-form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .form_item_box {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .send_sms_item_box {
        width: 100%;

        &.send_sms_describe {
          position: relative;

          .text_describe_lens {
            right: 10px;
            bottom: 2px;
          }
        }

        .ipt_describe {
          width: 100%;
          height: 140px;

          /deep/ .el-textarea__inner {
            width: 100% !important;
            height: 100% !important;
            display: flex;
          }
        }

      }
    }
  }

  .dialog_mainaer {
    .main_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .toast_box {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        padding-left: 30px;
      }

      .upload_file_box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        overflow: hidden;

        .label {
          min-width: 120px;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .btn_box {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 15px;
          // flex: 1;
          width: 244px;
          max-width: 244px;

          .uploadFile {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>