import {createApp} from 'vue'
import App from './App.vue'
// import router from './router/index';
import promise from './api/promise.js';
import router from './router/index.js'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css'
// process.env.NODE_ENV
// import locale from 'element-plus/lib/locale/lang/zh-cn'
// App.pre

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.use(promise, app)
app.mount('#app')