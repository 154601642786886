<template>
  <div class='sms_table_contanier' v-loading.fullscreen.lock="pageLoading">
    <div class="sms_table_title">
      <h1>Send Messaging Logs</h1>
    </div>
    <div class="search_tabs">
      <div class="search_contanier">

        <div class="select_item_box select_filter">
          <div class="select_label">短信ID</div>
          <div class="select_box">
            <el-input v-model="search_data.searchMsgSid" placeholder="搜索短信ID" :clearable="true"></el-input>
          </div>
          <div class="config_btns">
            <el-button class="search_sid" type="primary" @click="(eve)=>{Method.searchSid(eve)}" :disabled="searchFlag"
                       plain>搜索
            </el-button>
            <!-- :disabled="searchSid.length > 0 ? false:true"  -->
          </div>
        </div>
        <div class="select_item_box">
          <div class="select_label">开始时间</div>
          <div class="select_box">
            <el-date-picker v-model="search_data.select_start_time" type="date" placeholder="选择开始日期"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD">
            </el-date-picker>
          </div>
        </div>
        <div class="select_item_box">
          <div class="select_label">结束时间</div>
          <div class="select_box">
            <el-date-picker v-model="search_data.select_end_time" type="date" placeholder="选择结束日期"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD">
            </el-date-picker>
          </div>
        </div>
        <div class="select_item_box">
          <div class="select_label">发信号码</div>
          <div class="select_box">
            <el-input v-model="search_data.searchFrom" placeholder="搜索发信号码"></el-input>
          </div>
        </div>
        <div class="select_item_box">
          <div class="select_label">收信号码</div>
          <div class="select_box">
            <el-input v-model="search_data.searchTo" placeholder="搜索收信号码"></el-input>
          </div>
        </div>
        <div class="select_item_box">
          <div class="select_label">客户状态</div>
          <div class="select_box">
            <el-select v-model="search_data.customStatus" filterable placeholder="请选择客户状态">
              <el-option v-for="item in  SelectOptions.value" :key="item.value" :label="item.label"
                         :value="item.value"/>
              <!-- 默认的 发送失败的  过滤的  空号（录入的号码 看看之后能不能识别出来） -->
            </el-select>
          </div>
        </div>
        <!--        <div class="select_item_box">-->
        <!--          <div class="select_label">短信状态</div>-->
        <!--          <div class="select_box">-->
        <!--            <el-select v-model="search_data.MessageStatus" filterable placeholder="请选择短信状态">-->
        <!--              <el-option v-for="item in  MessageOptions.value" :key="item.value" :label="item.label"-->
        <!--                         :value="item.value"/>-->
        <!--              &lt;!&ndash; 默认的 发送失败的  过滤的  空号（录入的号码 看看之后能不能识别出来） &ndash;&gt;-->
        <!--            </el-select>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="select_item_box">
          <div class="select_label">短信读取状态</div>
          <div class="select_box">
            <el-select v-model="search_data.readStatus" filterable placeholder="请选择短信状态">
              <el-option v-for="item in  readStatusList.value" :key="item.value" :label="item.label"
                         :value="item.value"/>
              <!-- 默认的 发送失败的  过滤的  空号（录入的号码 看看之后能不能识别出来） value label -->
            </el-select>
          </div>
        </div>
        <div class="search_btn">
          <el-button class="search_sid" type="primary" @click="(eve)=>{Method.filterSearch(eve)}" plain>筛选</el-button>
          <el-button class="search_sid" type="primary" @click="(eve)=>{Method.searchRefresh(eve)}" plain>重置
          </el-button>
        </div>
      </div>
    </div>
    <div class="configuration_box">
      <el-button class="configuration_btn" type="primary" @click="(eve)=>{Method.doubleSend(eve)}" plain round>
        群发短信
      </el-button>
      <el-button class="configuration_btn" type="primary" @click="(eve)=>{Method.FileSend(eve)}" plain round>文件发送
      </el-button>
      <el-button class="configuration_btn" type="primary" @click="(eve)=>{Method.FileUpload(eve)}" plain round>
        上传文件
      </el-button>
    </div>
    <div class="table_boxs">
      <el-table class="table_main" ref="multipleTable" :data="tableData.value" tooltip-effect="dark" :max-height="530"
                @selection-change="(eve)=>{Method.handleSelectionChange(eve)}" :highlight-current-row="true"
                :border="true" :row-class-name="(item)=>Method.handleTableRowClass(item)">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="From" width="100" prop="From">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="To" width="100" prop="To">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="姓名" width="120" prop="custom_name">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="短信内容" prop="Body" :min-width="220">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="短信状态" width="120" prop="Status">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="发送时间" prop="SentDate">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="短信ID" prop="Sid">
        </el-table-column>
        <!--        <el-table-column :show-overflow-tooltip="true" label="媒体信息" width="120">-->
        <!--          <template #default="scope">-->
        <!--            <div class="media" v-if="scope.row.MediaUrl0 != ''">-->
        <!--              <img class="img_box" :src="scope.row.MediaUrl0">-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column :show-overflow-tooltip="true" label="标签ID" width="120">
          <template #default="scope">
            <!--            prop="label_id"-->
            <div class="label_name">
              <div class="label_item" v-for='ele in label_arr_list' :key='ele.label'>
                <!--                v-if="ele.label_id == scope.row.label_id"-->
                <!--                  label  v-if="ele.label_id == scope.row.label_id"-->
                {{ ele.label_id == scope.row.label_id ? ele.label : "" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- width="260" -->
        <el-table-column label="操作" :min-width="500">
          <template #default="scope">
            <!-- <el-button @click="Method.handleConfigs(scope.$index, scope.row,'edit')" plain round>编辑</el-button> -->

            <!--            <el-button type="danger" @click="()=>{Method.handleConfigs(scope.$index, scope.row,'filterateCustom')}"-->
            <!--                       v-if="scope.row.message_status == '1'" plain round>过滤-->
            <!--            </el-button>-->
            <el-button type="primary" @click="()=>{Method.handleConfigs(scope.$index, scope.row,'sendmsg')}" plain
                       round>发送短信
            </el-button>
            <el-button type="info" @click="()=>{Method.handleConfigs(scope.$index, scope.row,'userClass')}" plain round>
              用户分类
            </el-button>
            <el-button @click="()=>{Method.handleConfigs(scope.$index, scope.row,'editlogs')}" plain round>
              编辑用户
            </el-button>
            <el-button type="warning" v-if="scope.row.readStatus == 1"
                       @click="()=>{Method.handleConfigs(scope.$index, scope.row,'changeReadStatus')}" plain round>
              <!--              {{ scope.row.readStatus == 2 ? "已读" : scope.row.readStatus == 2 ?: ""}}-->
              已读
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination_box">
        <el-pagination class='pagination_main' background layout="prev, pager, next" :pager-count="7"
                       :total="paginatotal" :page-sizes="[10, 20, 30, 40, 50, 100]" :default-page-size="50"
                       :current-page="page_current"
                       @current-change="(num)=>{Method.pageChange(num)}">
        </el-pagination>
        <div class="total_num">
          当前总数{{ paginatotal }}条
        </div>
        <!-- paginatotal.value page-sizes="[10, 20, 30, 40, 50, 100]" -->
      </div>
    </div>
    <!-- 去发送 -->
    <el-dialog v-model="dialogFormVisible" :title="dialogConfigura.title || '发送短信'" @close="Method.dialogCancle"
               width="40%">
      <el-form :model="dialogConfigura">
        <el-form-item label="发信站点" :label-width="formLabelWidth" class="form_item_box">
          <div class="send_sms_item_box">
            <el-select @change="(eve)=>{Method.complies(eve)}" filterable class="ipt_box"
                       v-model="dialogConfigura.sms_site" slot="prepend" placeholder="请选择发送短信账号">
              <el-option :label="ele.send_name" :value="ele.id" v-for='ele,idx in sms_info.value'
                         :key='idx'></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="发信类型" :label-width="formLabelWidth" class="form_item_box"
                      v-if="dialogConfigura.sms_site != ''">
          <div class="send_sms_item_box">
            <el-select filterable class="ipt_box" v-model="dialogConfigura.sms_select_type" slot="prepend"
                       placeholder="请选择发送短信类型">
              <el-option :label="ele.label" :value="ele.value" v-for='ele,idx in sms_send_type_arr.value'
                         :key='idx'></el-option>
            </el-select>
          </div>
        </el-form-item>
        <!-- sms_send_type_arr -->
        <el-form-item label="发信电话" :label-width="formLabelWidth" class="form_item_box"
                      v-if="dialogConfigura.sms_select_type == 1">
          <div class="send_sms_item_box">
            <el-input class="ipt_box" :disabled="true" v-model="dialogConfigura.from_number" type="type" autosize
                      placeholder="请输入发信电话">
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="短信服务" :label-width="formLabelWidth" class="form_item_box"
                      v-if="dialogConfigura.sms_select_type == 2">
          <div class="send_sms_item_box">
            <el-input class="ipt_box" :disabled="true" v-model="dialogConfigura.messaging_service_sid" type="type"
                      autosize placeholder="请输入短信服务ID（MGXXXX）">
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="客户电话" :label-width="formLabelWidth" class="form_item_box"
                      v-if="dialogConfigura.sendType == 1">
          <div class="send_sms_item_box collapse_continaer_box">
            <!-- 放一批 选中的用户号码 -->
            <!-- <el-input class="ipt_box" :disabled="true" v-model="dialogConfigura.to_number" type="type" autosize placeholder="请选择收信号码">
            </el-input> -->
            <el-collapse v-model="dialogConfigura.activeNames" @change="Method.to_numberhandleChange" accordion>
              <el-collapse-item title="客户电话" name="1">
                <div class="collapse_box">
									<span class="collent_item" v-for='ele,idx in select_checkbox' :key='idx'>
										<el-badge class="item numbers">
											<el-button>{{ Method.formatNumber(ele.From) }}</el-button>
										</el-badge>
                    <!-- <span class="icon_box">
                      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
                        <path fill="currentColor"
                          d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                        </path>
                      </svg>
                    </span> -->
									</span>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-form-item>

        <el-form-item label="上传文件" :label-width="formLabelWidth" class="form_item_box"
                      v-if="dialogConfigura.sendType == 2">
          <div class="send_sms_item_box">
            <el-button class="uploadFile" @click="Method.uploadChangeHandle">
              {{ upload_send_csv_file.fileName == '' ? '请选择上传文件' : upload_send_csv_file.fileName }}
            </el-button>
            <div class="qucik_template">
              <el-tooltip content="下载群发文件模板" placement="top">
                <svg class="icon_InfoFilled" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                     data-v-ea893728="" @click.stop="()=>{Method.downFileQucik()}">
                  <path fill="currentColor"
                        d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296-44.096 0-108.992 44.736-148.48 101.504 0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04 67.84 0 107.904-43.648 147.456-100.416z"></path>
                </svg>
              </el-tooltip>
            </div>
            <!-- <el-input v-if="upload_send_csv_file.fileName != ''" v-model="upload_send_csv_file.fileName" type="button" placeholder="请选择上传文件" @click="Method.uploadChangeHandle" readonly></el-input> -->
            <!-- <el-upload ref="uploadref" class="upload-demo" :limit="1" :auto-upload="false" :show-file-list="false" @handleStart="Method.handleFile" :http-request="Method.handHttpFile"
              @abort="Method.handleFile" accept=".csv,.xlsx,.xls">

            </el-upload> -->
          </div>
        </el-form-item>
        <!-- v-if="dialogConfigura.sendType == 1" -->
        <el-form-item label="发送内容" :label-width="formLabelWidth" class="form_item_box send_item_text">
          <div class="send_sms_item_box">
            <div class="sms_main_box sms_send_text">
              <el-input v-model="dialogConfigura.send_msg" type="textarea" :autosize="{ minRows: 4, maxRows: 8}"
                        maxlength="160" placeholder="请输入发送内容">
              </el-input>
              <TextareaLens :max_lens="160" :min_lens="!!dialogConfigura.send_msg ? dialogConfigura.send_msg.length : 0"
                            class="send_msg_lens"></TextareaLens>
              <QucikTemplate :quickTemplate_check="(item)=>{Method.checkQuickTemplate(item)}"></QucikTemplate>
            </div>
          </div>
        </el-form-item>
        <div class="send_text_info">
          <p class="info">[name] Sample: Hello [name] 模版里面插入[name]模板的话，一定要用
            `[name]`，否则会无法识别而失效</p>
          <!--          your order [order] has been received. -->
          <!--          Order amount is [currency] [amount].-->
          <!--          We will deliver your order in 3 to 5 days. Thank you-->
        </div>
        <div class="form_item_box sms_sms_info_emoji">
          <IosEmojiLabel :emoji_change="(item)=>{Method.getEmojis(item)}"></IosEmojiLabel>
        </div>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="Method.dialogCancle">{{ dialogConfigura.cancleTitle || "取消" }}</el-button>
					<el-button type="primary"
                     @click="(eve)=>{Method.dialogcConfirm(eve)}">{{ dialogConfigura.entryTitle || "确定" }}</el-button>
				</span>
      </template>
    </el-dialog>
    <el-dialog class="dialog_mainaer" v-model="dialogFormVisible1" :title="dialogConfigura1.title || '上传短信记录'"
               @close="Method.dialogCancle1" width="50%">
      <div class="main_container"
           v-if="dialogConfigura1.dialogType == 1 || dialogConfigura1.dialogType == 2  || dialogConfigura1.dialogType == 3 || dialogConfigura1.dialogType == 4">
        <div class="toast_box" v-if="dialogConfigura1.dialogType == 1 || dialogConfigura1.dialogType == 2">
          {{
            dialogConfigura1.dialogType == 1 ? "上传Twilio官网短信记录需要较长时间，如无必要谨慎上传或分段上传！" : dialogConfigura1.dialogType == 2 ? `过滤用户${dialogConfigura1.From}后，用户会进入过滤组，所有批量操作都会受到影响，慎重使用！` : ''
          }}
        </div>
        <div class="upload_file_box" v-if="dialogConfigura1.dialogType == 1">
          <div class="label">上传记录</div>
          <div class="btn_box">
            <!-- <el-button v-if="upload_send_csv_file1.fileName == ''" class="uploadFile" @click="Method.uploadChangeHandle1">请选择上传文件</el-button>
            <el-input class="uploadFile" v-if="upload_send_csv_file1.fileName != ''" v-model="upload_send_csv_file1.fileName" type="button" placeholder="请选择上传文件" @click="Method.uploadChangeHandle1" readonly></el-input> -->
            <el-button class="uploadFile" @click="Method.uploadChangeHandle1">
              {{ upload_send_csv_file1.fileName == '' ? '请选择上传文件' : upload_send_csv_file1.fileName }}
            </el-button>
          </div>
        </div>
        <div class="user_bind_labels" v-if="dialogConfigura1.dialogType == 3">
          <div class="label_title">
            <!--            {{ dialogConfigura1["phone"] }}，用于用户维护。-->
            {{
              !dialogConfig1.sms_label_title ? `用户标签会绑定当前用户 ${dialogConfigura1["phone"]}，用于用户维护。` : `当前用户已绑定标签，重新绑定标签会清除之前已绑定的用户！`
            }}
          </div>
          <!--          dialogConfig1.sms_label_title-->
          <div class="checked">
            <el-form :model="dialogConfig1">
              <el-form-item label="标签名称" :label-width="120" class="form_item_box form_item_label_select">
                <div class="send_sms_item_box">
                  <el-select v-model="dialogConfig1.sms_label_title" class="select_box" placeholder="请选择标签"
                             size="large" @change="Method.label_change">
                    <el-option
                        v-for="item in label_select_arr.value"
                        :key="item.label"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                  <!--                  <el-input class="ipt_box" v-model="dialogConfig1.sms_label_title" type="text" autosize-->
                  <!--                            placeholder="请输入标签名称">-->
                  <!--                  </el-input>-->
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="edit_user_name" v-if="dialogConfigura1.dialogType == 4">
          <el-form :model="dialogConfig1">
            <el-form-item label="用户名称" :label-width="120" class="form_item_box form_item_label_select">
              <div class="send_sms_item_box">
                <el-input v-model="dialogConfig1.custom_name" class="select_box" placeholder="请输入用户名称"
                          type="text" maxlength="50" :clearable="true"/>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="Method.dialogCancle1">{{ dialogConfigura.cancleTitle || "取消" }}</el-button>
					<el-button type="primary"
                     @click="(eve)=>{Method.dialogcEntry(eve)}">{{ dialogConfigura.entryTitle || "确定" }}</el-button>
				</span>
      </template>
    </el-dialog>
    <input type="file" accept=".csv,.xlsx,.xls" ref="upload_files" class="upload_dm" @change="Method.handleFile">
    <input type="file" accept=".csv,.xlsx,.xls" ref="upload_files1" class="upload_dm" @change="Method.handleFile1">
    <!-- :hide-on-single-page="1" -->
    <!-- upload_files1 -->
  </div>
</template>
<script setup>
import {getCurrentInstance, onActivated, onMounted, reactive, ref, watch} from 'vue';
import TextareaLens from "@/component/TextareaLens.vue";
import IosEmojiLabel from "@/component/iosEmojiLabel.vue";
import QucikTemplate from '@/component/QucikTemplate.vue';

let app_base_url = ref("");
let refs1 = ref(0);
let Method = reactive({});
let tableData = reactive([]);
// let searchSid = ref("");
let searchSendNumber = ref("");
let searchacceptNumber = ref("");
let select_start_time = ref("");
let select_end_time = ref("");
let paginatotal = ref(0);
let searchFlag = ref(true);
let table_page = ref(1);
// let {proxy} = getCurrentInstance();
let _this = getCurrentInstance()["proxy"]
let pageLoading = ref(false);
let page_size = ref(50);
let page_lens = ref(1);
let page_current = ref(1);
let SelectOptions = reactive([]);
let MessageOptions = reactive([]);
let readStatusList = reactive([])
let customStatus = ref("");
let dialogFormVisible = ref(false);
let dialogFormVisible1 = ref(false);
let dialogConfigura = reactive({});
let dialogConfigura1 = reactive({});
let formLabelWidth = ref(120);
let sms_info = reactive({});
let search_data = reactive({});
let select_flag = ref(0);
let sms_info_config = reactive({});
let sms_send_type_arr = reactive([]);
let fileList = reactive([]);
let upload_send_csv_file = reactive({
  fileName: ""
});
let upload_send_csv_file1 = reactive({
  fileName: ""
});
let searchType = ref(1);
let search_filter = reactive({});
let select_checkbox = reactive([]);
let dialogConfig1 = reactive({})
let label_select_arr = reactive([])
let label_arr_list = reactive([])
let label_choose = reactive({})
let qucikTemplate_flags = ref(false)
// select_start_time
// ="select_end_time
onActivated(() => {
  console.log('dom加载');
  Method = reloads(0);
});
onMounted(() => {
  // console.log('home初始化',process.env,process.env["VUE_APP_BASE_URL"]);
  app_base_url.value = "https://m.rashineehair.com"
  Method = reloads(1);
});

function reloads(idx = 0) {
  // let _this = this;
  let _that = this;

  class Request {
    constructor() {
    }

    checkQuickTemplate(item) {
      console.log("快捷模板", item)
      if (!!dialogConfigura.send_msg) {
        dialogConfigura.send_msg = dialogConfigura.send_msg + item.quick_template_text
      } else {
        dialogConfigura.send_msg = "" + item.quick_template_text
      }
    }

    getEmojis(item) {
      // dialogConfigura.send_msg = dialogConfigura.send_msg + item.emoji_text
      if (!!dialogConfigura.send_msg) {
        dialogConfigura.send_msg = dialogConfigura.send_msg + item.emoji_text
      } else {
        dialogConfigura.send_msg = "" + item.emoji_text
      }
      // dialogConfigura.send_msg
    }

    init_Label() {
      // console.log("_this", _this,_this.$axhttp)
      _this.$axhttp({
        method: "get",
        url: "/api/label/all",
        params: {},
      }).then((res) => {
        console.log('输出res: api/label/all', res);
        if (res.code == 200) {
          let allCont_list = []
          if (res.data.allCont.length > 0) {
            res.data.allCont.forEach(function (ele, idx) {
              console.log("ele", ele, idx)
              // sms_label
              // id
              allCont_list.push({
                label: ele.sms_label || "",
                value: ele.id || "",
                label_id: ele.sms_label_id || ""
              })
            })
            label_select_arr.value = [...allCont_list]
            label_arr_list = [...allCont_list]
          }
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      })
    }

    initData() {
      console.log('初始化数据');
      this.reloadTable();
      this.reload_SMS_type_List();
      this.init_Label()
      // this.doubleSend();
      // this.FileSend();
      SelectOptions.value = [
        {
          id: 1,
          value: "1",
          label: "请选择客户状态"
        },
        {
          id: 2,
          value: "2",
          label: "过滤用户"
        },
        {
          id: 3,
          value: "3",
          label: "STOP屏蔽"
        },
        {
          id: 4,
          value: "4",
          label: "UNSUBSCRIBE屏蔽"
        },
        // 空号的暂时保留 做两份table使用
      ];
      // 1 delivered  2 undelivered  3 received 4 failed
      MessageOptions.value = [
        {
          id: 1,
          value: "",
          label: "请选择短信状态"
        },
        {
          id: 2,
          value: "1",
          label: "delivered"
        },
        {
          id: 3,
          value: "2",
          label: "undelivered"
        },
        {
          id: 4,
          value: "3",
          label: "received"
        },
        {
          id: 5,
          value: "4",
          label: "failed"
        },
      ];
      readStatusList.value = [
        {
          id: 1,
          value: "1",
          label: "未读"
        },
        {
          id: 2,
          value: "2",
          label: "已读"
        }
      ]
      // delivered   undelivered  received
      // 默认的 发送失败的  过滤的  空号
      // send_sms_item_box
      // qucik_template
      // icon_InfoFilled

    }

    reloadTable() {
      pageLoading.value = true;
      if (searchType.value == 1) {
        let limit = page_size.value;
        _this.$axhttp({
          method: "get",
          url: "/api/user/twiliotable/logs",
          params: {
            "limit": limit,
            "offset": (page_current.value - 1) * limit,
          }
        }).then((res) => {
          console.log('输出res:', res);
          if (res.code == 200) {
            // console.log('table表格:', res.data, tableData.value);
            tableData.value = [...res.data.allCont];
            paginatotal.value = res.data.total;
            // data.total
            page_lens.value = Math.ceil(res.data.total / limit);
            // tableData.value;
          }
          pageLoading.value = false;
        }).catch((err) => {
          console.log('错误信息?', err);
          pageLoading.value = false;
        });
      }
      if (searchType.value == 2) {
        let limit = page_size.value;
        let param_data = {
          "limit": limit,
          "offset": (page_current.value - 1) * limit,
        };
        if (search_data.searchMsgSid != "") {
          param_data["searchMsgSid"] = search_data.searchMsgSid;
        }
        param_data["type"] = "a";
        _this.$axhttp({
          method: "get",
          url: "/api/user/twiliotable/logs",
          params: param_data
        }).then((res) => {
          console.log('输出res:', res);
          if (res.code == 200) {
            // console.log('table表格:', res.data, tableData.value);
            tableData.value = [...res.data.allCont];
            paginatotal.value = res.data.total;
            // data.total
            page_lens.value = Math.ceil(res.data.total / limit);
            // tableData.value;
          }
          pageLoading.value = false;
        }).catch((err) => {
          console.log('错误信息?', err);
          pageLoading.value = false;
        });
      }
      if (searchType.value == 3) {
        let limit = page_size.value;
        // let search_filter =
        //           search_filter.start_time
        // search_filter.end_time
        // search_filter.from
        // search_filter.to
        // search_filter.customStatus
        let params_data = {
          "limit": limit,
          "offset": (page_current.value - 1) * limit,
        };
        params_data["type"] = "b";
        if (search_filter.start_time != "") {
          params_data["start_time"] = search_filter.start_time || "";
        }
        if (search_filter.end_time != "") {
          params_data["end_time"] = search_filter.end_time || "";
        }
        if (search_filter.from != "") {
          params_data["searchFrom"] = search_filter.from || "";
        }
        if (search_filter.to != "") {
          params_data["searchTo"] = search_filter.to || "";
        }
        if (search_filter.customStatus != "") {
          params_data["customStatus"] = search_filter.customStatus || "";
        }
        if (search_filter.MessageStatus != "") {
          params_data["searchMessageStatus"] = search_filter.MessageStatus || "";
        }
        // search_data.readStatus
        if (search_filter.readStatus != "") {
          params_data["readStatus"] = search_filter.readStatus || ""
        }
        // MessageStatus
        _this.$axhttp({
          method: "get",
          url: "/api/user/twiliotable/logs",
          params: params_data
        }).then((res) => {
          console.log('输出res:', res);
          if (res.code == 200) {
            // console.log('table表格:', res.data, tableData.value);
            tableData.value = [...res.data.allCont];
            paginatotal.value = res.data.total;
            // data.total
            page_lens.value = Math.ceil(res.data.total / limit);
            // tableData.value;
          }
          pageLoading.value = false;
        }).catch((err) => {
          console.log('错误信息?', err);
          pageLoading.value = false;
        });
      }
    }

    reload_SMS_type_List() {
      _this.$axhttp({
        method: "get",
        url: "/api/sms/getSMSList",
        params: {}
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          sms_info.value = [...res.data];
          // res.data
          // "from_number": "+16076227999",
          // "id": 1,
          // "send_name": "ashimaryhair"
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      });
    }

    downFileQucik() {
      console.log("下载 群发短信 模板")
      if (qucikTemplate_flags.value) {
        return false
      }
      qucikTemplate_flags.value = true
      console.log("下载", qucikTemplate_flags.value)
      let elea = document.createElement("a")
      // http://127.0.0.1:5522
      let path_link = app_base_url.value + "/file_path/twilio_send_sms_template.csv"
      elea.setAttribute("href", path_link)
      elea.setAttribute("target", "_blank")
      document.body.append(elea)
      elea.click()
      elea.remove()
      qucikTemplate_flags.value = false
      // document
    }

    doubleSend() {
      console.log('群发');
      // 在这里判断是否多选
      // select_checkbox
      if (select_checkbox.length > 0) {
        dialogConfigura.sendType = 1; //1是 table选择群发
        dialogConfigura.sms_site = "";
        dialogConfigura.activeNames = "";
        dialogConfigura.fileName = "";
        dialogFormVisible.value = true;
      } else {
        _this.$message({
          showClose: true,
          type: 'info',
          center: true,
          message: '群发短信需要选中表格中的收信单元格！',
          duration: 2000
        });
      }
    }

    FileSend() {
      dialogConfigura.sendType = 2;  // 2 是文件上传群发
      dialogConfigura.sms_site = "";
      dialogConfigura.activeNames = "";
      dialogConfigura.fileName = "";
      dialogFormVisible.value = true;
    }

    FileUpload() {
      // 上传文件
      dialogConfigura1["title"] = "上传短信记录";
      dialogConfigura1["dialogType"] = "1";
      dialogFormVisible1.value = true;
    }

    dialogCancle() {
      console.log('取消');
      dialogFormVisible.value = false;
      for (const key in dialogConfigura) {
        if (Object.hasOwnProperty.call(dialogConfigura, key)) {
          console.log('循环内容:', key, dialogConfigura, dialogConfigura[key]);
          // let ele = dialogConfigura[key];
          // ele = "";
          dialogConfigura[key] = "";
        }
      }
      //  upload_files
      _this.$refs.upload_files.value = "";
      _this.$refs.upload_files.value = null;
      _this.$refs.upload_files.innerText = "";
      _this.$refs.upload_files.innerText = null;
      upload_send_csv_file.fileObj = {};
      upload_send_csv_file.fileName = "";
      // _this.$refs.multipleTable.clearSelection();
      // clearSelection 清空 默认选择
      // toggleRowSelection 选中当前行
      // select_checkbox = [];
    }

    dialogCancle1() {
      console.log('关闭');
      // dialogConfigura1
      dialogFormVisible1.value = false;
      for (const key in dialogConfigura1) {
        if (Object.hasOwnProperty.call(dialogConfigura1, key)) {
          if (key == "dialogType") {
            dialogConfigura1[key] = "1";
            continue;
          }
          dialogConfigura1[key] = "";
        }
      }
      for (const key in dialogConfig1) {
        if (Object.hasOwnProperty.call(dialogConfig1, key)) {
          dialogConfig1[key] = "";
        }
      }
      // dialogConfig1.custom_name
      _this.$refs.upload_files1.value = "";
      _this.$refs.upload_files1.value = null;
      _this.$refs.upload_files1.innerText = "";
      _this.$refs.upload_files1.innerText = null;
      upload_send_csv_file1.fileObj = {};
      upload_send_csv_file1.fileName = "";
    }

    dialogcEntry() {
      console.log('确定 按钮');
      // dialogFormVisible1.value = false;
      if (pageLoading.value) {
        return false;
      }
      pageLoading.value = true;
      if (dialogConfigura1.dialogType == 1) {
        if (upload_send_csv_file1.fileName == "") {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请先上传文件',
            duration: 2000
          });
        }
        // console.log('上传文件');
        // _this.$refs.upload_files1.click();
        let files = upload_send_csv_file1.fileObj;
        let dataForm = new FormData();
        dataForm.append("files", files);
        _this.$axhttp({
          method: "post",
          url: "/api/files/upload_all_csv_file",
          header: {
            "Content-Type": "multipart/form-data;"
          },
          data: dataForm
        }).then((res) => {
          console.log('输出res:', res);
          if (res.code == 200) {
            pageLoading.value = false;
            dialogFormVisible1.value = false;
            _this.$message({
              showClose: true,
              type: 'success',
              message: `添加成功！总长度${res.data.max_coutns_total || 0} 添加成功${res.data.success_counts_total || 0}条 添加失败${res.data.fail_counts_total || 0}条 筛选条件${res.data.filter_counts_total || 0}条`,
              duration: 2000
            });
          }
        }).catch((err) => {
          console.log('错误信息?', err);
          pageLoading.value = false;
        });
      }
      if (dialogConfigura1.dialogType == 2) {
        console.log('过滤用户');
        // ["id"]
        // From"]
        let data = {
          "id": dialogConfigura1["id"] || "",
          "From": dialogConfigura1["From"] || ""
        };
        _this.$axhttp({
          method: "post",
          url: "/api/msg/status/filterate",
          data: data
        }).then((res) => {
          console.log('输出res:', res);
          pageLoading.value = false;
          if (res.code == 200) {
            _this.$message({
              showClose: true,
              type: 'success',
              message: '编辑成功！',
              duration: 2000
            });
            setTimeout(() => {
              // pageLoading.value = false;
              dialogFormVisible1.value = false;
              this.reloadTable();
            }, 1500);
          } else {
            _this.$message({
              showClose: true,
              type: 'success',
              message: res.msg,
              duration: 2000
            });
          }
        }).catch((err) => {
          console.log('错误信息?', err);
          pageLoading.value = false;
        });
      }
      // 上传文件
      // 绑定用户
      if (dialogConfigura1.dialogType == 3) {
        console.log('绑定用户');
        this.label_bind_user()
        // dialogConfigura1["label_id"]
        // dialogConfigura1["id"]
        // dialogConfig1.sms_label_title
      }
      // 编辑用户名
      if (dialogConfigura1.dialogType == 4) {
        console.log('编辑用户名');
        // this.label_bind_user()
        // dialogConfig1.custom_name
        // dialogConfig1.custom_name
        this.changeLogUserName()
      }
    }
    changeLogUserName() {
      // let id = rows.id
      let custom_name = dialogConfig1.custom_name || ""
      let id = dialogConfigura1.id || ""
      // dialogConfigura1["id"] = rows.id || "";
      // dialogConfig1.custom_name = rows.custom_name
      let phones_number = ''
      // dialogConfigura1["line_data"]
      for (let i = 0; i < sms_info.value.length; i++) {
        let ele = sms_info.value[i]
        if (ele.from_number == dialogConfigura1["line_data"]["To"]) {
          phones_number = dialogConfigura1["line_data"]["From"]
        }
        if (ele.from_number == dialogConfigura1["line_data"]["From"]) {
          phones_number = dialogConfigura1["line_data"]["To"]
        }
      }
      let rows = {}
      _this.$axhttp({
        method: "post",
        url: "/api/sms/log/change_custom_name",
        data: {
          "id": id,
          "custom_name":custom_name,
          "bind_phone":phones_number
        }
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          // pageLoading.value = false
          _this.$message({
            showClose: true,
            type: 'success',
            center: true,
            message: res.msg || `成功设置用户名 ${custom_name}！`,
            duration: 2000
          })
          setTimeout(() => {
            pageLoading.value = false;
            dialogFormVisible1.value = false;
            this.reloadTable();
          }, 1500);
        } else {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: res.msg || '消息无效！',
            duration: 1500
          })
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      })
    }
    label_bind_user() {
      // dialogConfig1.custom_name
      let label_id = ""
      label_select_arr.value.forEach(function (ele, idx) {
        console.log("ele", ele, idx, dialogConfig1.sms_label_title)
        // label: ele.sms_label || "",
        // value: ele.id || "",
        // label_id: ele.sms_label_id || ""
        if (ele.value == dialogConfig1.sms_label_title) {
          label_id = ele.label_id
        }
      })
      console.log("dialogConfigura1", dialogConfigura1["line_data"])
      // sms_info.value
      // from_number: "18882027782"
      // id: 2
      let phones_number = ''
      for (let i = 0; i < sms_info.value.length; i++) {
        let ele = sms_info.value[i]
        if (ele.from_number == dialogConfigura1["line_data"]["To"]) {
          phones_number = dialogConfigura1["line_data"]["From"]
        }
        if (ele.from_number == dialogConfigura1["line_data"]["From"]) {
          phones_number = dialogConfigura1["line_data"]["To"]
        }
      }
      _this.$axhttp({
        method: "post",
        url: "/api/label/bind/user",
        data: {
          "label_id": label_id,
          "log_id": dialogConfigura1["id"],
          "bind_phone": phones_number,
        }
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          console.log("成功")
          _this.$message({
            showClose: true,
            type: 'success',
            center: true,
            message: res.msg || '绑定成功！',
            duration: 1500
          })
          setTimeout(() => {
            // pageLoading.value = false;
            dialogFormVisible1.value = false;
            this.reloadTable();
            this.dialogCancle1()
          }, 1000);
        } else {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: res.msg || '参数错误！',
            duration: 1500
          })
          setTimeout(() => {
            pageLoading.value = false;
          }, 1000)
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      })
    }

    dialogcConfirm() {
      console.log('确认');
      // upload_send_csv_file.fileObj
      // upload_send_csv_file.filename
      //         dialogConfigura.sms_select_type
      // dialogConfigura.sms_site
      // dialogConfigura.sendType
      // dialogConfigura.messaging_service_sid
      // dialogConfigura.from_number
      let sendType = dialogConfigura.sendType;
      if (pageLoading.value) {
        return false;
      }
      pageLoading.value = true;
      if (sendType == 1) {
        // let { _this } = getCurrentInstance();
        if (dialogConfigura.sms_site == "") {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请选择站点！',
            duration: 2000
          });
          pageLoading.value = false;
          return false;
        }
        if (dialogConfigura.sms_select_type == "") {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请选择发信类型！',
            duration: 2000
          });
          pageLoading.value = false;
          return false;
        }
        if (dialogConfigura.send_msg == "") {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请填写发送内容！',
            duration: 2000
          });
          pageLoading.value = false;
          return false;
        }
        let data = {};
        let phone_list_number = {};
        select_checkbox.forEach((ele, idx) => {
          if (phone_list_number[ele.From] == undefined) {
            phone_list_number[ele.From] = 1;
          }
        });
        data["sms_site_type"] = dialogConfigura.sms_site || "";
        data["sms_server_select"] = dialogConfigura.sms_select_type || "";
        data["body"] = dialogConfigura.send_msg || "";
        // data  sms_select_type
        data["phone_list_number"] = Object.keys(phone_list_number).join(",");
        // dataForm.append("sms_server_select", dialogConfigura.sms_select_type || "");
        // dataForm.append("body", dialogConfigura.send_msg || "");
        // console.log('上传的手机号:', data);
        _this.$axhttp({
          method: "post",
          url: "/api/pushList/list_to_number",
          data: data
        }).then((res) => {
          // console.log('输出res:', res);
          pageLoading.value = false;
          if (res.code == 200) {
            let max_send_number = res.data.max_send_number || 0
            let send_successed_number = res.data.send_successed_number || 0
            let filter_custom_number = res.data.filter_custom_number || 0
            let send_failed_number = res.data.send_failed_number || 0
            let send_msg_str = "本次群发短信总数: " + max_send_number + "," + "本次群发短信成功数: " + send_successed_number + "," + "本次群发短信退订筛选数: " + filter_custom_number + "," + "本次群发短信失败数: " + send_failed_number
            _this.$message({
              showClose: true,
              type: 'success',
              message: res.msg,
              duration: 2000
            });
            setTimeout(() => {
              dialogFormVisible.value = false;
            }, 1500);
          }
        }).catch((err) => {
          // console.log('错误信息?', err);
          pageLoading.value = false;
        });
      }
      if (sendType == 2) {
        if (upload_send_csv_file.fileName == "") {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请选择上传的文件！',
            duration: 2000
          });
          pageLoading.value = false;
          return false;
        }
        if (dialogConfigura.sms_site == "") {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请选择站点！',
            duration: 2000
          });
          pageLoading.value = false;
          return false;
        }
        if (dialogConfigura.sms_select_type == "") {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请选择发信类型！',
            duration: 2000
          });
          pageLoading.value = false;
          return false;
        }
        if (dialogConfigura.send_msg == "") {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: '请填写发送内容！',
            duration: 2000
          });
          pageLoading.value = false;
          return false;
        }
        // 发信类型
        // 发送内容
        let dataForm = new FormData();
        dataForm.append("files", upload_send_csv_file.fileObj);
        dataForm.append("sms_site_type", dialogConfigura.sms_site || "");
        dataForm.append("sms_server_select", dialogConfigura.sms_select_type || "");
        dataForm.append("body", dialogConfigura.send_msg || "");
        _this.$axhttp({
          method: "post",
          url: "/api/files/upload_to_number",
          header: {
            "Content-Type": "multipart/form-data;"
          },
          data: dataForm
        }).then((res) => {
          console.log('输出res:', res);
          pageLoading.value = false;
          // "max_send_number": max_send_number,
          //     "send_failed_number": send_failed_number,
          //     "send_successed_number": send_successed_number,
          //     "filter_custom_number": filter_custom_number,
          if (res.code == 200) {
            let max_send_number = res.data.max_send_number || 0
            let send_successed_number = res.data.send_successed_number || 0
            let filter_custom_number = res.data.filter_custom_number || 0
            let send_failed_number = res.data.send_failed_number || 0
            let send_msg_str = "本次群发短信总数: " + max_send_number + "," + "本次群发短信成功数: " + send_successed_number + "," + "本次群发短信退订筛选数: " + filter_custom_number + "," + "本次群发短信失败数: " + send_failed_number
            _this.$message({
              showClose: true,
              type: 'success',
              message: send_msg_str || res.msg,
              duration: 2000
            });
            setTimeout(() => {
              dialogFormVisible.value = false;
            }, 1500);
          }
        }).catch((err) => {
          console.log('错误信息?', err);
          pageLoading.value = false;
        });
      }
      // if (sendType == 4) {
      //   // dialogConfig1.custom_name
      // }
    }

    formatNumber(numbers) {
      let strs = '';
      // numbers.
      strs = numbers.replace(/(\d{3})\d{4}(\d{4})/g, '$1****$2');
      return strs;
    }

    searchSid() {
      // console.log('搜搜sid:', search_data.searchMsgSid);
      if (search_data.searchMsgSid != "") {
        searchType.value = "2";
      } else {
        searchType.value = "1";
      }
      this.reloadTable();
      // _this.$axhttp({
      // 	method: "get",
      // 	url: "/api/",
      // 	params: {}
      // }).then((res) => {
      // 	console.log('输出res:', res);
      // }).catch((err) => {
      // 	console.log('错误信息?', err);
      // });
    }

    complies() {
      if (dialogConfigura.sms_site.value != "") {
        // console.log('sms_info.value', sms_info.value.length);
        for (let i = 0; i < sms_info.value.length; i++) {
          const ele = sms_info.value[i];
          // console.log('遍历:',ele.send_name,dialogConfigura.sms_site.value);
          if (ele.id == dialogConfigura.sms_site) {
            // form_number.value = ele["from_number"];
            sms_info_config = {...ele};
          }
        }
        select_flag.value = 1;
        let from_arr = [
          {
            id: 1,
            value: "1",
            label: "Phone Number"
          },
          {
            id: 2,
            value: "2",
            label: "Messaging Service"
          }
        ];
        sms_send_type_arr.value = [...from_arr];
      } else {
        select_flag.value = 0;
      }
    }

    filterSearch() {
      //  searchSendNumber
      // searchacceptNumber
      // select_start_time
      // select_end_time
      // select_start_time
      // select_end_time
      //  search_data.select_start_time
      //  search_data.select_end_time
      // console.log('搜搜sid:', search_data, search_data.select_start_time, search_data.select_end_time);
      let select_start_time = search_data.select_start_time || "";
      let select_end_time = search_data.select_end_time || "";
      let searchFrom = search_data.searchFrom || "";
      let searchTo = search_data.searchTo || "";
      let customStatus = search_data.customStatus || "";
      let MessageStatus = search_data.MessageStatus || "";
      let readStatus = search_data.readStatus || "";
      // console.log('时间格式化:', select_start_time, select_end_time, searchFrom, searchTo, customStatus);
      search_filter.start_time = select_start_time || "";
      search_filter.end_time = select_end_time || "";
      search_filter.from = searchFrom || "";
      search_filter.to = searchTo || "";
      search_filter.customStatus = customStatus || "";
      search_filter.MessageStatus = MessageStatus || "";
      search_filter.readStatus = readStatus || "";
      searchType.value = "3";
      // console.log('搜索 筛查:', this);
      // console.log('搜索 筛查2:', _this.Method);
      this.reloadTable();
      // console.log('内容:', searchacceptNumber.value, select_start_time.value, select_end_time.value);
    }

    searchRefresh() {
      // console.log('搜索 refresh 重置');
      searchType.value = "1";
      for (const key in search_data) {
        if (Object.hasOwnProperty.call(search_data, key)) {
          search_data[key] = "";
        }
      }
      this.reloadTable();
    }

    handleTableRowClass(item) {
      // console.log("tabRowClassChange", item, item.row.readStatus)
      // readStatus
      if (item.row.readStatus == 1) {
        return "unReadStatus"
      }
      if (item.row.readStatus == 2) {
        return "ReadedStatus"
      }
      return ''
    }

    handleSelectionChange(res) {
      // console.log('获取选中的值:', res);
      select_checkbox = [...res];
    }

    uploadChangeHandle() {
      // console.log('上传文件', _this);
      // console.log('_this', _this.$refs.upload);
      // console.log('sas1', _this.$refs.upload_files);
      // .upload_files
      _this.$refs.upload_files.click();
      // upload
    }

    uploadChangeHandle1() {
      // console.log('上传文件', _this);
      // console.log('_this', _this.$refs.upload);
      // console.log('sas1', _this.$refs.upload_files);
      // .upload_files
      _this.$refs.upload_files1.click();
      // upload
    }

    handleFile(file) {
      // console.log('选择的文件:', file);
      fileList = file.target.files;
      // console.log('fileList', fileList[0]);
      // fileList[0].name filename
      upload_send_csv_file.fileObj = fileList[0];
      upload_send_csv_file.fileName = fileList[0].name;
      // console.log('upload_send_csv_file', upload_send_csv_file);
      _this.$refs.upload_files.value = "";
      _this.$refs.upload_files.value = null;
      _this.$refs.upload_files.innerText = "";
      _this.$refs.upload_files.innerText = null;
      // let urls = window.URL.createObjectURL(fileList[0]);
      // console.log(urls, fileList);//这个就是选中文件信息
    }

    handleFile1(file) {
      fileList = file.target.files;
      // console.log('fileList', fileList[0]);
      // fileList[0].name filename
      upload_send_csv_file1.fileObj = fileList[0];
      upload_send_csv_file1.fileName = fileList[0].name;
      // console.log('upload_send_csv_file', upload_send_csv_file);
      _this.$refs.upload_files1.value = "";
      _this.$refs.upload_files1.value = null;
      _this.$refs.upload_files1.innerText = "";
      _this.$refs.upload_files1.innerText = null;
      // this.
    }

    handHttpFile(res) {
      // dialogFormVisible1.value = false;

    }

    handleConfigs(index, rows, type) {
      console.log('功能按钮', index, rows, type);
      //  'del'
      // 'sendmsg'
      if (type == 'filterateCustom') {
        console.log('过滤用户', rows, rows.id, rows.From);
        // id From
        dialogConfigura1["dialogType"] = "2";
        dialogConfigura1["id"] = rows.id || "";
        dialogConfigura1["From"] = rows.From || "";
        dialogConfigura1["title"] = "过滤用户";
        dialogFormVisible1.value = true;
      }
      if (type == 'sendmsg') {
        console.log('发送短信');
        select_checkbox = [];
        select_checkbox.push({...rows});
        _this.$refs.multipleTable.toggleRowSelection(rows, true);
        // toggleRowSelection 选中当前行
        // 直接走 单个群发
        this.doubleSend();
      }
      if (type == 'userClass') {
        _this.$refs.multipleTable.toggleRowSelection(rows, true);
        pageLoading.value = true
        this.changeUserClass(rows);
      }
      if (type == "changeReadStatus") {
        console.log("已读")
        pageLoading.value = true
        this.changeReadStatus(rows)
      }
      if (type == "editlogs") {
        // pageLoading.value = true
        dialogConfigura1["dialogType"] = "4";
        dialogConfigura1["id"] = rows.id || "";
        dialogConfigura1["title"] = "编辑用户名";
        dialogFormVisible1.value = true;
        dialogConfigura1["line_data"] = {...rows}
        if (rows.custom_name != "") {
          dialogConfig1.custom_name = rows.custom_name
        } else {
          dialogConfig1.custom_name = ""
        }
        // dialogConfigura1["line_data"]["From"]
        // this.changeLogUserName(rows)
      }
    }

    changeUserClass(rows) {
      _this.$axhttp({
        method: "get",
        url: "/api/label/all",
        params: {},
      }).then((res) => {
        console.log('输出res: api/label/all', res);
        if (res.code == 200) {
          let allCont_list = []
          if (res.data.allCont.length > 0) {
            res.data.allCont.forEach(function (ele, idx) {
              console.log("ele", ele, idx)
              // sms_label
              // id
              allCont_list.push({
                label: ele.sms_label || "",
                value: ele.id || "",
                label_id: ele.sms_label_id || ""
              })
            })
          }
          label_select_arr.value = [...allCont_list]
          label_arr_list = [...allCont_list]
        }
        pageLoading.value = false
        select_checkbox = [];
        select_checkbox.push({...rows});
        dialogConfigura1["dialogType"] = "3";
        dialogConfigura1["id"] = rows.id || "";
        dialogConfigura1["line_data"] = {...rows}
        // dialogConfigura1["label_id"] = rows.id || "";
        // dialogConfig1.sms_label_title =
        // label_id
        // label_select_arr
        // label_id

        if (!!rows.label_id && rows.label_id != "" && rows.label_id.length > 0) {
          label_arr_list.forEach(function (ele, idx) {
            console.log("ele", ele, idx)
            if (ele.label_id == rows.label_id) {
              dialogConfigura1["label_id"] = ele.label_id
              dialogConfig1.sms_label_title = ele.value
            }

          })
        } else {
          //   多绑定处理
        }
        let phones_number = ''
        for (let i = 0; i < sms_info.value.length; i++) {
          let ele = sms_info.value[i]
          if (ele.from_number == dialogConfigura1["line_data"]["To"]) {
            phones_number = dialogConfigura1["line_data"]["From"]
          }
          if (ele.from_number == dialogConfigura1["line_data"]["From"]) {
            phones_number = dialogConfigura1["line_data"]["To"]
          }
        }
        dialogConfigura1["phone"] = phones_number || "";
        if (!!rows.label_id && rows.label_id != "" && rows.label_id.length > 0) {
          dialogConfigura1["title"] = "修改标签";
        } else {
          dialogConfigura1["title"] = "绑定标签";
        }
        dialogFormVisible1.value = true;
      }).catch((err) => {
        console.log('错误信息?', err);
      })
    }

    changeReadStatus(rows) {
      _this.$axhttp({
        method: "post",
        url: "/api/readStatus/change",
        data: {
          "id": rows.id,
          "readStatus": rows.readStatus == "1" ? "2" : ""
        }
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          pageLoading.value = false
          _this.$message({
            showClose: true,
            type: 'success',
            center: true,
            message: res.msg || "成功设置邮件为已读！",
            duration: 2000
          })
          setTimeout(() => {
            // pageLoading.value = false;
            // dialogFormVisible1.value = false;
            this.reloadTable();
          }, 1500);
        } else {
          _this.$message({
            showClose: true,
            type: 'info',
            center: true,
            message: res.msg || '消息无效！',
            duration: 1500
          })
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      })
    }


    label_change(val) {
      console.log("修改标签:", val)
    }

    handleEdit(index, rows) {
      console.log('编辑', index, rows);
    }

    to_numberhandleChange(val) {
      console.log('展开');
      console.log(val);
    }

    handleDel() {

    }

    handleSend() {

    }

    pageChange(num) {
      console.log('num', num, page_current.value);
      page_current.value = num;
      this.reloadTable();
    }
  }

  let method = new Request();
  if (idx) {
    method.initData();
  }
  return method;
}

// searchSid
// dialogConfigura.sms_select_type
watch(search_data, (nowval, oldval) => {
  // console.log('监听searchSid', nowval, oldval);
  // SM
  console.log('nowval.searchSid', nowval.searchMsgSid);
  // searchSid
  if (!!nowval.searchMsgSid) {
    if (nowval.searchMsgSid.length >= 34) {
      searchFlag.value = false;
    }
  }
});
watch(dialogConfigura, (nowval, oldval) => {
  // console.log('监听searchSid', nowval, oldval, sms_info_config);
  //     sms_info_config.from_number
  // sms_info_config.messaging_service_sid
  if (nowval.sms_select_type == "1") {
    // id: 1,
    // value: "1",
    // label: "Phone Number"

    dialogConfigura.from_number = sms_info_config.from_number;
  }
  if (nowval.sms_select_type == "2") {
    dialogConfigura.messaging_service_sid = sms_info_config.messaging_service_sid;
  }
})
</script>
<style lang='less' type='text/less' scoped>
.sms_table_contanier {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;

  .sms_table_title {
    width: 100%;
    height: 44px;
    font-size: 32 rpx;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0;
    padding-left: 30px;

    h1 {
      font-size: 32 rpx;
      color: rgb(18, 28, 45);
      font-weight: 700;
      display: block;
      line-height: 44px;
    }
  }

  .search_tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 10px;

    .search_contanier {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      .select_item_box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;

        .select_label {
          min-width: 120px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 32px;
          font-size: 14px;
          color: #333;
        }

        .select_box {
          height: 32px;
          margin-left: 15px;
          // height: 220px;
          min-width: 220px;
        }

        &.select_filter {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .select_box {
            width: 340px;
          }

          .config_btns {
            margin-left: 20px;
          }
        }
      }
    }

    .search_btn {
      margin-left: 30px;
    }
  }

  .configuration_box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 20px;

    .configuration_btn {
      margin-right: 10px;
    }
  }

  .table_boxs {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .pagination_box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin: 15px 0;

      .pagination_main {
      }

      .total_num {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        padding-left: 30px;
      }
    }

    .table_main {
      width: 100%;

      /deep/ .el-table__body-wrapper {
        .el-table__body {
          .el-table__row {
            &.unReadStatus {
              background: rgb(254, 245, 238);
              border-color: rgb(225, 227, 234);
              border-width: 0px 0px 1px;
            }
          }
        }
      }

      .media {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .img_box {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .el-overlay {
    .el-dialog__body {
      width: 100%;
      padding: 0 30px;

      .el-form {
        .el-form-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70%;

          .el-form-item__content {
            max-width: 214px;
            flex: 0.5;
          }

          &:last-of-type {
            align-items: flex-start;
          }
        }
      }
    }

    .el-dialog__footer {
      border-top: 1px solid #eee;
    }


    .form_item_box {
      .el-form-item__content {
        min-width: 214px;
        display: flex;
        justify-content: center;
        align-items: center;

        .send_sms_item_box {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .ipt_box {
            width: 100%;
          }

          .uploadFile {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          &.collapse_continaer_box {
            width: 100%;

            .el-collapse {
              width: 100%;

              .el-collapse-item {
                width: 100%;

                .collapse_box {
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  flex-wrap: wrap;

                  .collent_item {
                    margin-right: 6px;
                    margin-bottom: 12px;
                  }

                  .el-badge {
                    .el-button {
                      padding: 6px 10px;
                    }
                  }
                }
              }
            }

            .collent_item {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }

          .qucik_template {
            padding-left: 10px;
            height: 32px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .icon_InfoFilled {
              font-size: 16px;
              color: #7a7a7a;
              width: 16px;
              height: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              &:hover {
                color: #4191ea;
                text-decoration: underline;
              }
            }
          }
        }
      }

    }
  }

  .el-form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .form_item_box {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .sms_main_box {
        &.sms_send_text {
          position: relative;
          width: 100%;

          .send_msg_lens {
            right: 4px;
            bottom: 4px;
          }
        }
      }

      &.send_item_text {
        margin-bottom: 10px;
      }

      &.sms_sms_info_emoji {
        width: 100%;

        .container_main {
          padding-left: 120px;
          padding-right: 70px;
        }
      }
    }

    .send_text_info {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 120px;
      flex-wrap: wrap;
      color: #7a7a7a;

      .info {
        margin-bottom: 4px;
        font-size: 12px;
      }
    }

    .form_item_label_select {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center !important;
      margin-top: 20px !important;
    }
  }

  .upload_dm {
    width: 0;
    height: 0;
    display: none;
  }

  .dialog_mainaer {
    .main_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .toast_box {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        padding-left: 30px;
      }

      .upload_file_box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        overflow: hidden;

        .label {
          min-width: 120px;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .btn_box {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 15px;
          // flex: 1;
          width: 244px;
          max-width: 244px;

          .uploadFile {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }

      .user_bind_labels {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .label_title {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          padding-left: 120px;
        }
      }
    }
  }
}
</style>