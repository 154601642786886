<template>
  <div v-loading.fullscreen.lock="pageLoading" class="home_contanier">
    <div class="send_sms_main_contanier">
      <div class="send_sms_item_box">
        <div class="sms_label">
          发送站点
        </div>
        <div class="sms_main_box">
          <el-select slot="prepend" v-model="sms_site" class="ipt_box" filterable
                     placeholder="请选择发送短信账号" @change="(eve)=>{Method.complies(eve)}">
            <el-option v-for='ele,idx in sms_info.value' :key='idx' :label="ele.send_name" :value="ele.id"></el-option>
          </el-select>
        </div>
      </div>
      <div v-if="select_flag" class="send_sms_item_box">
        <div class="sms_label">
          推送电话
        </div>
        <div class="sms_main_box">
          <el-input v-model="form_number" autosize class="ipt_box" disabled="true" placeholder="请输入发送电话"
                    type="type">
          </el-input>
        </div>
      </div>
      <div class="send_sms_item_box">
        <div class="sms_label">
          客户电话
        </div>
        <div class="sms_main_box">
          <el-input v-model="to_number" autosize class="ipt_box" placeholder="请输入客户电话" type="type">
          </el-input>
        </div>
      </div>
      <div class="send_sms_item_box send_sms_info">
        <div class="sms_label">
          发送内容
        </div>
        <div class="sms_main_box">
          <el-input class="ipt_box" v-model="send_msg" :autosize="{ minRows: 4, maxRows: 8}" maxlength="152"
                    placeholder="请输入发送内容"
                    type="textarea">
          </el-input>
          <TextareaLens :max_lens="160" :min_lens="!!send_msg ? send_msg.length : 0"
                        class="send_msg_lens"></TextareaLens>
          <QucikTemplate :quickTemplate_check="(item)=>{Method.checkQuickTemplate(item)}"></QucikTemplate>
        </div>
      </div>
      <div class="send_text_info">
        <p class="info">[name] Sample: Hello [name] 模版里面插入[name]模板的话，一定要用
          `[name]`，否则会无法识别而失效</p>
        <p class="info">输入内容不要随意出现双引号",会默认转换为单引号'</p>
        <!--          your order [order] has been received. -->
        <!--          Order amount is [currency] [amount].-->
        <!--          We will deliver your order in 3 to 5 days. Thank you-->
      </div>
      <div class="send_sms_item_box sms_sms_info_emoji">
        <IosEmojiLabel :emoji_change="(item)=>{Method.getEmojis(item)}"></IosEmojiLabel>
      </div>
      <div class="send_btn">
        <el-button class="sendBtn" type="primary" @click.stop="(eve)=>{Method.pushSmSMsg(eve)}">发送短信</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, onMounted, reactive, ref} from 'vue';
import TextareaLens from "@/component/TextareaLens.vue";
import IosEmojiLabel from "@/component/iosEmojiLabel.vue";
import QucikTemplate from '@/component/QucikTemplate.vue'

let refs1 = ref(1);
let sms_site = ref("");
let to_number = ref("");
let form_number = ref("");
let send_msg = ref("");
let select_flag = ref(false);
let Method = reactive({});
let pageLoading = ref(false);
let {proxy} = getCurrentInstance();
let sms_info = reactive([]);
// onActivated(() => {
//   console.log('初始化');
//
// });
onBeforeMount(() => {
  console.log("beforeMount home")
  Method = reloads();
})
onMounted(() => {
  console.log('home初始化',);
  Method = reloads(1);
});

function reloads(idx = 0) {
  let _this = this;

  class Request {
    constructor() {

    }

    checkQuickTemplate(item) {
      console.log("快捷模板", item)
      send_msg.value = send_msg.value + item.quick_template_text
    }

    getEmojis(item) {
      // console.log("getEmojis:", item, item.emoji_text)
      send_msg.value = send_msg.value + item.emoji_text
    }

    initData() {
      console.log('初始化init');
      // http://127.0.0.1:5121/api/sms/getSMSList
      pageLoading.value = true
      this.getSmsInfo();
    }

    complies() {
      if (sms_site.value != "") {
        select_flag.value = true;
        console.log('sms_info.value', sms_info.value.length);
        for (let i = 0; i < sms_info.value.length; i++) {
          const ele = sms_info.value[i];
          // console.log('遍历:',ele.send_name,sms_site.value);
          if (ele.id == sms_site.value) {
            form_number.value = ele["from_number"];
          }
        }
      } else {
        select_flag.value = false;
      }
    }

    getSmsInfo() {
      // proxy
      proxy.$axhttp({
        method: "get",
        url: "/api/sms/getSMSList",
        params: {}
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          pageLoading.value = false
          sms_info.value = [...res.data];
          // res.data
          // "from_number": "+16076227999",
          // "id": 1,
          // "send_name": "ashimaryhair"
        }
      }).catch((err) => {
        console.log('错误信息?', err);
      });
    }

    pushSmSMsg() {
      if (pageLoading.value) {
        return false;
      }
      pageLoading.value = true;
      // let { proxy } = getCurrentInstance();
      // id
      let data = {
        twilio_id: ""
      };
      sms_info.value.forEach((ele, idx) => {
        if (ele.id == sms_site.value) {
          data["twilio_id"] = ele["id"];
        }
      });
      data["form_number"] = form_number.value || "";
      data["to_number"] = to_number.value || "";
      data["send_msg"] = send_msg.value || "";
      // send_msg
      proxy.$axhttp({
        method: "post",
        url: "/api/push/twilio_info",
        data: data
      }).then((res) => {
        console.log('输出res:', res);
        if (res.code == 200) {
          proxy.$notify({
            title: '成功',
            message: res.msg,
            type: 'success',
            duration: 2000
          });
          pageLoading.value = false;
        } else {
          proxy.$notify({
            title: '警告',
            message: res.msg,
            type: 'warning',
            duration: 2000
          });
          pageLoading.value = false;
        }
      }).catch((err) => {
        console.log('错误信息?', err);
        pageLoading.value = false;
      });
    }
  }

  let method = new Request();
  if (idx) {
    method.initData();
  }
  return method;
}
</script>

<style lang='less' scoped type='text/less'>
.home_contanier {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .send_sms_main_contanier {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 40%;

    .send_sms_item_box {
      width: 100%;
      min-height: 38px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      font-size: 18px;

      &.send_sms_info {
        margin-bottom: 10px;

        .sms_main_box {
          position: relative;

          .send_msg_lens {
            right: 4px;
            bottom: 4px;
          }
        }
      }

      .sms_label {
        min-width: 120px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
      }

      .sms_main_box {
        margin-left: 15px;
        flex: 1;
        min-height: 38px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .el-select {
          height: 38px;
        }

        .ipt_box {
          min-height: 38px;
          width: 100%;
          font-size: 14px;

          /deep/ .el-input__inner {
            font-size: 14px;
          }
        }
      }

      &:nth-of-type(4) {
        align-items: flex-start;

        .sms_label {
          padding-top: 6px;
        }
      }
    }

    .send_text_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 135px;
      flex-wrap: wrap;
      color: #7a7a7a;

      .info {
        margin-bottom: 4px;
        font-size: 12px;
      }
    }

    .send_btn {
      width: 120px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;

      .sendBtn {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>